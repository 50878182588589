import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";

export default function EventDetails() {

  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Tədbir haqqında")} title={t("Əsas")} subtitle={t("Tədbir haqqında")} />
    </>
  );
}
