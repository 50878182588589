import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function DashboardPolls() {
  const navigate = useNavigate();
  const [polls, setPolls] = useState([]); // Anketleri tutacak state
  const [departments, setDepartments] = useState([]); // Departmanları tutacak state
  const [question, setQuestion] = useState(""); // Yeni anket sorusu için state
  const [departmentId, setDepartmentId] = useState(""); // Yeni anket için departman seçimi
  const [options, setOptions] = useState([]); // Seçenekleri tutacak state
  const [optionText, setOptionText] = useState(""); // Seçenek metni için state

  // Anketleri ve departmanları backend'den çek
  useEffect(() => {
    axios.get("https://www.hemkarbamid.az/hemkarbamid/get-polls.php")
      .then(response => {
        setPolls(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error("Anketler alınamadı: ", error);
      });

    axios.get("https://www.hemkarbamid.az/hemkarbamid/get-departments.php")
      .then(response => {
        setDepartments(response.data);
      })
      .catch(error => {
        console.error("Departmanlar alınamadı: ", error);
      });
  }, []);

  // Yeni anket eklemek için form submit işlemi
  function handleAddPoll(e) {
    e.preventDefault();
    const newPoll = {
      question: question,
      department_id: departmentId,
      options: options // Seçenekleri de ekle
    };

    axios.post("https://www.hemkarbamid.az/hemkarbamid/add-poll.php", newPoll)
      .then(response => {
        alert("Anket başarıyla eklendi!");
        setPolls([...polls, response.data]);
        setQuestion("");
        setDepartmentId("");
        setOptions([]); // Seçenekleri temizle
        setOptionText(""); // Seçenek metnini temizle
      })
      .catch(error => {
        console.error("Anket eklenirken hata oluştu: ", error);
      });
  }

  // Seçenek eklemek için fonksiyon
  function handleAddOption() {
    if (optionText) {
      setOptions([...options, { text: optionText }]); // Yeni seçeneği ekle
      setOptionText(""); // Seçenek metnini sıfırla
    }
  }

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const user = localStorage.getItem("user");

  return (
    <>
      <DashboardHeader />
      <div className="dashboard-content">
        <h2>Anketler</h2>
        <ul>
          {polls?.map(poll => (
            <li key={poll.id}>
              {poll.question} - {poll.department_name}
              <ul>
                {/* Poll seçeneklerini göster */}
                {poll.options && poll.options.map((option, index) => (
                  <li key={index}>{option.text}</li> // Seçenek metni
                ))}
              </ul>
            </li>
          ))}
        </ul>

        {/* Yeni Anket Ekleme Formu */}
        <h3>Yeni Anket Ekle</h3>
        <form onSubmit={handleAddPoll}>
          <label>Soru:</label>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />

<label>Departman:</label>
<select
  value={departmentId}
  onChange={(e) => setDepartmentId(e.target.value)}
  required
>
  <option value="">Departman Seçin</option>
  <option value="all">Herkes</option> {/* Herkes için bir seçenek ekleyin */}
  {departments?.map((department) => (
    <option key={department.id} value={department.id}>
      {department.department_name}
    </option>
  ))}
</select>


          {/* Seçenek Ekleme */}
          <label>Seçenek Ekle:</label>
          <input
            type="text"
            value={optionText}
            onChange={(e) => setOptionText(e.target.value)}
            required
          />
          <button type="button" onClick={handleAddOption}>Seçenek Ekle</button>

          <ul>
            {options.map((option, index) => (
              <li key={index}>{option.text}</li> // Seçenekleri göster
            ))}
          </ul>

          <button type="submit">Anket Ekle</button>
        </form>
      </div>
    </>
  );
}


