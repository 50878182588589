import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function DashboardResults() {
  const [results, setResults] = useState([]); // Oylama sonuçları durumu
  const [loading, setLoading] = useState(true); // Yüklenme durumu
  const [error, setError] = useState(null); // Hata durumu

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get("https://www.hemkarbamid.az/hemkarbamid/get-poll-results.php");
        setResults(response.data);
      } catch (err) {
        setError("Sonuçlar alınamadı: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Anket sonuçlarını gruplayarak göstermek için bir yardımcı fonksiyon
  const groupResultsByPoll = (results) => {
    return results.reduce((acc, result) => {
      const { poll_id, poll_question, option_id, option_text, vote_count } = result;
      
      if (!acc[poll_id]) {
        acc[poll_id] = { question: poll_question, options: [] };
      }
      
      acc[poll_id].options.push({ option_id, option_text, vote_count });
      return acc;
    }, {});
  };

  const groupedResults = groupResultsByPoll(results);

  return (
    <>
      <DashboardHeader />
      <div className="dashboard-results">
        <h2>Oylama Sonuçları</h2>
        {Object.keys(groupedResults).length === 0 ? (
          <p>Henüz oylama yapılmamış.</p>
        ) : (
          <ul>
            {Object.keys(groupedResults).map((pollId) => (
              <li key={pollId}>
                <h3>{groupedResults[pollId].question}</h3>
                <ul>
                  {groupedResults[pollId].options.map((option) => (
                    <li key={option.option_id}>
                      <span>{option.option_text}: {option.vote_count} oy</span>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}


