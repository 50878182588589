import React from "react";
import Heading from "../../Components/home/Heading";
import { useTranslation } from "react-i18next";
import { partners10, partners11, partners12, partners13, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, titleLine } from "../../assets";

export const price = [
  {
    image: partners2,
    path: 'https://shamakhipalace.az/az'
  },
  {
    image: partners3,
    path: 'https://voguehotel.az/'
  },
  {
    image: partners4,
    path: 'https://planetofhotels.com/en/azerbaijan/qusar/ilk-inn-hotel'
  },
  {
    image: partners5,
    path: 'https://www.riverinn.az/en'
  },
  {
    image: partners6,
    path: 'https://galaalti.az/az'
  },
  {
    image: partners7,
    path: 'https://elresort.az/az/'
  },
  {
    image: partners8,
    path: 'https://yurdhotel.az/az/'
  },
  {
    image: partners9,
    path: 'https://www.marxalresort.az/'
  },
  {
    image: partners10,
    path: 'https://qubapalace.com/'
  },
  {
    image: partners11,
    path: 'https://elfaro.az/'
  },
  {
    image: partners12,
    path: 'https://www.bravosupermarket.az/'
  },
  {
    image: partners13,
    path: 'https://qayali.az/'
  },
];

export default function Partners() {
  const { t } = useTranslation();

  return (
    <>
      <Heading heading={t("Tərəfdaşlarımız")} title={t("Əsas")} subtitle={t("Tərəfdaşlarımız")} />
      <div className="md:max-w-container max-w-containerSm mx-auto md:mt-20 md:mb-20 mt-10 mb-10">
        <h1 className="md:text-4xl text-[22px] font-extrabold mt-2 tracking-tight text-center">Tərəfdaşlarımız</h1>
        <img className="w-52 mx-auto mt-2 mb-5" src={titleLine} alt="Title Line" />
        <div className='grid grid-cols-1 md:grid-cols-4 md:gap-4 gap-6'>
          {price.map((item, index) => (
            <div key={index} className="flex justify-center shadow-md">
              <a href={item.path} target="_blank" rel="noopener noreferrer">
                <img src={item.image} alt={`item-${index}`} className="h-32 object-contain hover:scale-105 transition-transform duration-200" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
