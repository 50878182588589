import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './partners.css'
import { useTranslation } from "react-i18next";
import { partners10, partners11, partners12, partners13, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, titleLine } from "../../../assets";

export const price = [ 
  {
    image: partners2,
    path: 'https://shamakhipalace.az/az' 
  },
  {
    image:partners3,
    path: 'https://voguehotel.az/'
  },  
  {
    image: partners4,
    path: 'https://planetofhotels.com/en/azerbaijan/qusar/ilk-inn-hotel'
  },
  {
    image: partners5,
    path: 'https://www.riverinn.az/en'
  },
  {
    image: partners6,
    path: 'https://galaalti.az/az'
  },
  {
    image: partners7,
    path: 'https://elresort.az/az/'
  },
  {
    image: partners8,
    path: 'https://yurdhotel.az/az/'
  },
  {
    image: partners9,
    path: 'https://www.marxalresort.az/'
  },
  {
    image: partners10,
    path: 'https://qubapalace.com/'
  },
  {
    image: partners11,
    path: 'https://elfaro.az/'
  },
  {
    image: partners12,
    path: 'https://www.bravosupermarket.az/'
  },
  {
    image: partners13,
    path: 'https://qayali.az/'
  },
]; 

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className} slick-prev`} onClick={onClick}>
      &#8592; {/* Left arrow icon */}
    </button>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className} slick-next`} onClick={onClick}>
      &#8594; {/* Right arrow icon */}
    </button>
  );
};

const Partners = () => {

  const { t, i18n } = useTranslation();
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div  style={{ 
      backgroundImage: `linear-gradient(#F7F8FB, rgba(0, 0, 0, 0.1)), url('./map.png')`, 
      height: '70vh',
      maxHeight: '70vh'
    }} className="containerSm md:container-xxl py-5 h-screen flex flex-col justify-center">
     <div className="md:max-w-container max-w-containerSm mx-auto">
     <div className="md:container max-w-containerSm">
     <h1 className="text-[22px] md:text-3xl font-extrabold text-center mb-5 ">{t("Tərəfdaşlarımız")}</h1>
     <img className="w-52 mx-auto mb-10" src={titleLine}></img>
        <div >
          <Slider {...settings}> 
            {price.map((data, index) => (
              <div className="flex justify-center items-center">
                <div className="h-40 w-40 mx-4 bg-[white]" key={index}>
                 <a target="_blank" rel="noopener noreferrer" href={data.path}>
                 <img
                    src={data.image}
                    alt={data.plan}
                    className="testimonial-img h-40 w-40 object-contain"
                  />
                 </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
     </div>
    </div>
  );
};

export default Partners;