import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AboveHeader from "./Components/AboveHeader/AboveHeader";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Contact from "./Pages/Contact/Contact";
import Partners from "./Pages/Partners/Partners";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Documents from "./Pages/Documents/Documents.js";
import MiddleHeader from "./Components/MiddleHeader/MiddleHeader.js";
import Offers from "./Pages/Offers/Offers.js";
import Login from "./Pages/Login/Login.js";
import Register from "./Pages/Register/Register.js";
import Faq from "./Pages/Faq/Faq.js";
import Team from "./Pages/Team/Team.js";
import Events from "./Pages/Events/Events";
import Activity from "./Pages/Activity/Activity"; 
import News from "./Pages/News/News";
import EventDetails from "./Pages/Events/EventDetails";
import NewsDetails from "./Pages/News/NewsDetails";
import Statistics from "./Pages/Statistics/Statistics";
import Laws from "./Pages/Laws/Laws";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import CreateUser from "./Pages/CreateUser/CreateUser";
import EditUser from "./Pages/EditUser/EditUser";
import Protected from "./Pages/Protected/Protected";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Pending from "./Pages/Pending/Pending";
import DashboardOffers from "./Pages/DashboardOffers/DashboardOffers";
import SearchResults from "./Pages/SearchResults/SearchResult";
import Polls from "./Pages/Polls/Polls";
import DashboardPolls from "./Pages/DashboardPolls/DashboardPolls";
import DashboardResults from "./Pages/DashboardResults/DashboardResults";
import DashboardApplications from "./Pages/DashboardApplications/DashboardApplications.jsx";
import ScrollToTop from 'react-scroll-to-top';
import './App.css'
import HeaderMobile from "./Components/HeaderMobile/HeaderMobile.js";

const Layout = () => {
  return ( 
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <AboveHeader />
      <MiddleHeader/>
      <Header /> 
      <HeaderMobile/>
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/documents" element={<Documents />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/offers" element={<Offers />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/faq" element={ <Faq />}></Route>
        <Route path="/team" element={ <Team />}></Route>
        <Route path="/events" element={ <Events />}></Route>
        <Route path="/events/:id" element={ <EventDetails />}></Route>
        <Route path="/activity" element={ <Activity />}></Route>
        <Route path="/news" element={ <News />}></Route>
        <Route path="/news/:id" element={ <NewsDetails />}></Route>
        <Route path="/register" element={ <Register />}></Route>
        <Route path="/statistics" element={ <Statistics />}></Route>
        <Route path="/polls" element={ <Polls />}></Route>
        <Route path="/laws" element={ <Laws />}></Route>
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/dashboard/pending" element={<Pending />} /> 
        <Route path="/dashboard/offers" element={<DashboardOffers />} />
        <Route path="/dashboard/applications" element={<DashboardApplications />} />
        <Route path="/dashboard/polls" element={<DashboardPolls />} />
        <Route path="/dashboard/results" element={<DashboardResults />} />
        <Route path="/search/:query" element={<SearchResults />} />
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
      <Route path="dashboard/create"  element={<Protected Component={CreateUser} />} />
      <Route path="dashboard/:id/edit"  element={<Protected Component={EditUser} />} />
      
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="">
      <div className="font-bodyFont">
      <div>
      <ScrollToTop className="custom-scroll-to-top mb-20" smooth />
      <FloatingWhatsApp  phoneNumber='+994502062566' accountName="SOCAR Hemkarbamid" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
  );
}

export default App;