import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Polls = () => {
  const department = useSelector((state) => state.auth.department); // Kullanıcının departmanı
  const userId = useSelector((state) => state.auth.userId); // Kullanıcı ID'sini alın
  const [polls, setPolls] = useState([]); // Anketler durumu
  const [loading, setLoading] = useState(true); // Yükleniyor durumu
  const [error, setError] = useState(null); // Hata durumu
  const [message, setMessage] = useState(null); // Başarı mesajı
  const [votedPolls, setVotedPolls] = useState(new Set()); // Kullanıcının oy verdiği anketler

  useEffect(() => {
    const fetchPolls = async () => {
      try {
        // Hem kullanıcının departmanına özel anketler hem de herkes için olan anketleri al
        const response = await axios.get(
          `https://www.hemkarbamid.az/hemkarbamid/get-polls.php?department_id=${department}&include_global=true`
        );
        setPolls(response.data);
      } catch (err) {
        setError('Anketler alınamadı: ' + err.message);
      } finally {
        setLoading(false); 
      }
    };

    fetchPolls();
  }, [department]); // Departman değiştiğinde tekrar çağrılır

  const handleVote = useCallback(async (pollId, optionId) => {
    if (votedPolls.has(pollId)) {
      setError('Bu ankete zaten oy verdiniz.');
      return;
    }

    try {
      await axios.post('https://www.hemkarbamid.az/hemkarbamid/vote.php', {
        poll_id: parseInt(pollId, 10), // poll_id'yi integer'a çevir
        option_id: parseInt(optionId, 10), // option_id'yi integer'a çevir
        department_id: parseInt(department, 10), // department_id'yi integer'a çevir
        user_id: parseInt(userId, 10), // user_id'yi integer'a çevir
      });
      setMessage('Oyunuz başarıyla kaydedildi!');
      setError(null); // Önceki hataları temizle
      setVotedPolls((prev) => new Set(prev).add(pollId));
    } catch (err) {
      setError('Oy kaydedilemedi: ' + err.message);
      setMessage(null); // Önceki mesajları temizle
    }
  }, [department, userId, votedPolls]);

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Anketler</h2>
      {message && <div className="alert alert-success">{message}</div>}
      {polls.length === 0 ? (
        <p>Bu departman için anket bulunamadı.</p>
      ) : (
        <ul>
          {polls.map(poll => (
            <li key={poll.id}>
              <div>{poll.question}</div>
              <ul>
                {poll.options && poll.options.map(option => (
                  <li key={option.id}>
                    <span>{option.option_text}</span>
                    <button onClick={() => handleVote(poll.id, option.id)} disabled={votedPolls.has(poll.id)}>Oyla</button>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Polls;



