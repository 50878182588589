import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from "../../Components/home/Heading";

const Faq = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('tab-one');
  const [activeAccordions, setActiveAccordions] = useState({});

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAccordionToggle = (index) => {
    setActiveAccordions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      {/* Page Title */}
      <Heading heading={t("FAQ")} title={t("Əsas")} subtitle={t("FAQ")} />

      {/* FAQ Section */}
      <section className="md:max-w-container max-w-containerSm mx-auto py-12">
        <div className="md:container containerSm mx-auto text-center">
          <h2 className="text-[22px] md:text-3xl font-bold mb-8">{t("Tez-tez verilən suallar")}</h2>

          {/* Tabs */}
          <div className="mb-8">
            <ul className="flex justify-center space-x-4">
              <li
                className={`cursor-pointer py-2 px-4 ${activeTab === 'tab-one' ? 'border-b-4 border-[#223555] text-[#223555]' : 'text-gray-500'}`}
                onClick={() => handleTabClick('tab-one')}
              >
                Sənədlər
              </li>
              <li
                className={`cursor-pointer py-2 px-4 ${activeTab === 'tab-two' ? 'border-b-4 border-[#223555] text-[#223555]' : 'text-gray-500'}`}
                onClick={() => handleTabClick('tab-two')}
              >
                Əlaqə
              </li>
              <li
                className={`cursor-pointer py-2 px-4 ${activeTab === 'tab-three' ? 'border-b-4 border-[#223555] text-[#223555]' : 'text-gray-500'}`}
                onClick={() => handleTabClick('tab-three')}
              >
               Üzvlər
              </li>
            </ul>
          </div>

          {/* Accordion */}
          <div className="space-y-4">
            {/* Tab 1 Content */}
            {activeTab === 'tab-one' && (
              <>
                <div className="border border-gray-200 rounded-lg">
                  <div
                    className="flex justify-between items-center p-4 bg-gray-50 cursor-pointer"
                    onClick={() => handleAccordionToggle(1)}
                  >
                    <h4 className="text-lg font-semibold">What is the International Court of Justice?</h4>
                    <span className="text-gray-600">{activeAccordions[1] ? '-' : '+'}</span>
                  </div>
                  {activeAccordions[1] && (
                    <div className="p-4 bg-white">
                      <p className="text-sm text-gray-600">
                        Only States are eligible to appear before the Court in contentious cases. At present, this
                        basically means the 192 United Nations Member States.
                      </p>
                    </div>
                  )}
                </div>

                <div className="border border-gray-200 rounded-lg">
                  <div
                    className="flex justify-between items-center p-4 bg-gray-50 cursor-pointer"
                    onClick={() => handleAccordionToggle(2)}
                  >
                    <h4 className="text-lg font-semibold">Who may submit cases to the Court?</h4>
                    <span className="text-gray-600">{activeAccordions[2] ? '-' : '+'}</span>
                  </div>
                  {activeAccordions[2] && (
                    <div className="p-4 bg-white">
                      <p className="text-sm text-gray-600">
                        Only States are eligible to appear before the Court in contentious cases. At present, this
                        basically means the 192 United Nations Member States.
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Tab 2 Content */}
            {activeTab === 'tab-two' && (
              <>
                <div className="border border-gray-200 rounded-lg">
                  <div
                    className="flex justify-between items-center p-4 bg-gray-50 cursor-pointer"
                    onClick={() => handleAccordionToggle(3)}
                  >
                    <h4 className="text-lg font-semibold">What is the International Court of Justice?</h4>
                    <span className="text-gray-600">{activeAccordions[3] ? '-' : '+'}</span>
                  </div>
                  {activeAccordions[3] && (
                    <div className="p-4 bg-white">
                      <p className="text-sm text-gray-600">
                        Only States are eligible to appear before the Court in contentious cases. At present, this
                        basically means the 192 United Nations Member States.
                      </p>
                    </div>
                  )}
                </div>

                <div className="border border-gray-200 rounded-lg">
                  <div
                    className="flex justify-between items-center p-4 bg-gray-50 cursor-pointer"
                    onClick={() => handleAccordionToggle(4)}
                  >
                    <h4 className="text-lg font-semibold">Who may submit cases to the Court?</h4>
                    <span className="text-gray-600">{activeAccordions[4] ? '-' : '+'}</span>
                  </div>
                  {activeAccordions[4] && (
                    <div className="p-4 bg-white">
                      <p className="text-sm text-gray-600">
                        Only States are eligible to appear before the Court in contentious cases. At present, this
                        basically means the 192 United Nations Member States.
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Tab 3 Content */}
            {activeTab === 'tab-three' && (
              <>
                <div className="border border-gray-200 rounded-lg">
                  <div
                    className="flex justify-between items-center p-4 bg-gray-50 cursor-pointer"
                    onClick={() => handleAccordionToggle(5)}
                  >
                    <h4 className="text-lg font-semibold">What is the International Court of Justice?</h4>
                    <span className="text-gray-600">{activeAccordions[5] ? '-' : '+'}</span>
                  </div>
                  {activeAccordions[5] && (
                    <div className="p-4 bg-white">
                      <p className="text-sm text-gray-600">
                        Only States are eligible to appear before the Court in contentious cases. At present, this
                        basically means the 192 United Nations Member States.
                      </p>
                    </div>
                  )}
                </div>

                <div className="border border-gray-200 rounded-lg">
                  <div
                    className="flex justify-between items-center p-4 bg-gray-50 cursor-pointer"
                    onClick={() => handleAccordionToggle(6)}
                  >
                    <h4 className="text-lg font-semibold">Who may submit cases to the Court?</h4>
                    <span className="text-gray-600">{activeAccordions[6] ? '-' : '+'}</span>
                  </div>
                  {activeAccordions[6] && (
                    <div className="p-4 bg-white">
                      <p className="text-sm text-gray-600">
                        Only States are eligible to appear before the Court in contentious cases. At present, this
                        basically means the 192 United Nations Member States.
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
