import React, { useState } from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Offers() {

  const { t, i18n } = useTranslation(); 
    const navigate = useNavigate()
    const memberName = useSelector((state) => state.auth.member);
    const memberSurname = useSelector((state) => state.auth.surname);
    const userId = useSelector((state) => state.auth.userId); // Redux'dan userId'yi al

    const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };


const handleSubmit = async (e) => {
    e.preventDefault();
  
    if(memberName && memberSurname) {
        // Add offer and created_at field
        let inputs = {
            name: memberName,
            surname: memberSurname,
            offer: inputValue, // Assuming the input is for the offer
            created_at: new Date().toISOString() // Auto-generating the created_at field
        };
    
        try {
            console.log(inputs);
      
            const response = await axios.post("https://www.hemkarbamid.az/hemkarbamid/offers.php/", inputs);
      
            toast.success("Form göndərildi");
      
            setInputValue(""); // Clear the input
      
            navigate('/');
      
        } catch (error) {
            console.error("Sipariş gönderilirken bir hata oluştu:", error);
            toast.error("Form göndərilərkən xəta baş verdi");
        }
    } else {
        navigate('/login')
    }
};


  return (
    <>
      <Heading heading={t("Rəy və təklif")} title={t("Əsas")} subtitle={t("Haqqımızda")} />

      <div className="md:max-w-container max-w-containerSm mx-auto">
      <form onSubmit={handleSubmit} className="bg-white py-6 rounded-lg">
        <div className="mb-4">
          <input
            type="text"
            id="inputField"
            value={inputValue}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
            placeholder="Yazın..."
          />
        </div>
        <button
          type="submit"
          className="bg-[#223555] rounded-xl text-white py-2 md:py-3 px-8 hover:bg-gray-800"
        >
          {t("Göndər")}
        </button>
      </form>
      </div>
    </>
  );
}
