import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const SearchResults = () => {
    const { t, i18n } = useTranslation();
  const { query } = useParams(); // Route parametresinden arama sorgusunu al

  return (
    <div>
      <h1>{t("Search Results for")}: {query}</h1>
      {/* Buraya API'den ya da veri kaynağından sonuçları getirebilirsiniz */}
    </div>
  );
};

export default SearchResults;
