import  React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, logoLeft, logoRight, mpost } from "../../assets/index";
import { useTranslation } from "react-i18next";
import BrandSlider from '../BrandSlider/BrandSlider'


const MiddleHeader = () => {
  const { t, i18n } = useTranslation();

  
  return ( 
    <> 
     <div className="bg-[#f3f3f3] h-[138px] hidden md:block">
     <div className="mx-auto max-w-container ">
     <nav>
          <div className="flex justify-between items-center mx-auto "> 
            <div>
              <a href='/'> <img src={logoLeft} alt="Logo" className="h-32 mt-1" /></a>
            </div>  
            <div> 
           <BrandSlider/>
            </div>
            <div className="w-[280px] font-['Contrail_One'] italic font-black">
  <p className="text-[#056DA6]">Hər həmkar, sənin uğur hekayəndə </p> 
  <p className="text-[#E53037]">Bir fəsil açar, birlikdə yazdığımız </p>
  <p className="text-[#008000]">Tarix zamanla daha qiymətli olacaq</p>
</div>

 
          </div>
      </nav>
      </div>        </div>          
    </>
  ); 
};

export default MiddleHeader;
