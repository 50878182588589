import React from 'react';
import { bgPhoto, titleLine } from '../../assets/index';

const Heading = ({ heading, title, subtitle }) => { 
  return (
    <>
    <div className="relative bg-gray-900 bg-opacity-80 text-white h-40 md:h-80 flex flex-col items-center justify-center">
      <img src={bgPhoto} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-80" />
      <div className="relative z-10 text-center px-4">
        <h1 className="md:text-5xl text-[24px] font-extrabold mt-2 tracking-tight">{heading}</h1>
        {/* <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{title}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">{subtitle}</span>
        </div> */}
         <img className="w-52 mx-auto md:mt-5 mt-2" src={titleLine}></img>
      </div> 
    </div>
    <div className='bg-[#f7fafd]'>
    <div className='max-w-containerSm md:max-w-container mx-auto flex justify-between items-center h-16'>
    <p className='text-base hidden md:block md:text-[20px] font-semibold'>{heading}</p>
    <p className='text-base mx-auto md:mx-0 md:text-[20px] font-semibold'>Ana səhifə - {heading}</p>
    </div>
    </div>
    </>
  );
};

export default Heading;
