import React, { useState } from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Register() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [father, setFather] = useState("");
  const [hidNumber, setHidNumber] = useState("");
  const [finKod, setFinKod] = useState("");
  const [email, setEmail] = useState("");
  const [innerNumber, setİnnerNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [birthday, setBirthday] = useState("");
  const [acceptanceToWork, setAcceptanceToWork] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState(null);
  const [martyrDocument, setMartyrDocument] = useState(null);
  const [isMartyr, setIsMartyr] = useState("0");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlemartyrDocumentChange = (e) => {
    setMartyrDocument(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(finKod.length !== 7) {
      toast.error("FinKod 7-simvoldan ibarət olmalıdır");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Şifrələr bərabər deyil");
      return;
    }
    if (isMartyr === '1' && martyrDocument === null) {
      toast.error("Şəhid və ya qaziliyi təsdiq edən sənəd daxil edilməyib");
      return;
    }
  
    const formData = new FormData();
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("father", father);
    formData.append("hidNumber", hidNumber);
    formData.append("finKod", finKod);
    formData.append("email", email);
    formData.append("innerNumber", innerNumber);
    formData.append("mobileNumber", mobileNumber);
    formData.append("birthday", birthday);
    formData.append("acceptanceToWork", acceptanceToWork);
    formData.append("department", department);
    formData.append("position", position);
    formData.append("isMartyr", isMartyr);
    formData.append("password", password);
    if (image) {
      formData.append("image", image);
    }
    if (martyrDocument) {
      formData.append("martyrDocument", martyrDocument);
    }
  
    axios
      .post("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(formData)
        toast.success(
          "Admin tərəfindən təsdiq edildikdən sonra daxil etdiyiniz iş vəsiqə nömrəsi və şifrə ilə hesabınıza daxil ola bilərsiniz."
        );
        navigate("/login");
      })
      .catch((error) => {
        toast.error("Bir xəta baş verdi.");
      });
  };
  return (
    <>
      <Heading heading={t("Qeydiyyat")} title={t("Əsas")} subtitle={t("Qeydiyyat")} />
      <div className="p-6 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Ad")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Ad"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Soyad")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Soyad"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">{t("Ata adı")}</label>
                  <input
                    type="text"
                    value={father}
                    onChange={(e) => setFather(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Ata adı"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Doğum tarixi")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Doğum tarixi"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                  {t("Şəkil")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className="w-full p-2 border rounded-md"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">{t("Email")}</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("İşə qəbul tarixi")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    value={acceptanceToWork}
                    onChange={(e) => setAcceptanceToWork(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="İşə qəbul tarixi"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("İş vəsiqə nömrəsi")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    value={hidNumber}
                    onChange={(e) => setHidNumber(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="İş vəsiqə nömrəsi"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                  {t("Şəxsiyyət vəsiqəsi FİN Kod")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={finKod}
                    onChange={(e) => setFinKod(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="FinKod"
                    required
                  />
                </div>
                <div className="mb-4">
  <label className="block text-gray-700">{t("Şöbə/Sahə")}</label>
  <select
  value={department}
  onChange={(e) => setDepartment(e.target.value)}
  className="w-full p-2 border rounded-md"
>
  <option value="" disabled>
    Şöbə/Sahə seçin
  </option>
  <option value="Rehberlik">Rəhbərlik</option>
  <option value="Rehberlik_yaninda_apparat">Rəhbərlik yanında apparat</option>
  <option value="Istehsalat_texniki_sobesi">İstehsalat-texniki şöbəsi</option>
  <option value="Muhafize_sistemleri">Mühafizə sistemlərinə nəzarət və buraxılış şöbəsi</option>
  <option value="Energetika">Energetika şöbəsi</option>
  <option value="Mexanika">Mexanika şöbəsi</option>
  <option value="Avtomatlasdirma_metrologiya">İstehsalat proseslərinin avtomatlaşdırılması və metrologiya şöbəsi</option>
  <option value="Saglamliq_tehlukesizlik">Sağlamlıq, əməyin təhlükəsizliyi, ətraf mühitin mühafizəsi və keyfiyyətə nəzarət şöbəsi</option>
  <option value="Iqtisadiyyat_ucot">İqtisadiyyat və Uçot şöbəsi</option>
  <option value="Icra_nezaret">İcraya nəzarət, kargüzarlıq və təsərrüfat işləri şöbəsi</option>
  <option value="Xarici_elaqeler">Xarici əlaqələr şöbəsi</option>
  <option value="Huquq">Hüquq şöbəsi</option>
  <option value="Insan_resurslari">İnsan resursları şöbəsi</option>
  <option value="Temir_tikinti">Əsaslı təmir və tikinti şöbəsi</option>
  <option value="Komplektlesdirmetechizat">Komplektləşdirmə və təchizat şöbəsi</option>
  <option value="Texniki_nezaret">Texniki nəzarət şöbəsi</option>
  <option value="Lab_keyfiyyet_nezaret">İstehsalat və keyfiyyətə nəzarət laboratoriyası</option>
  <option value="Karbamid_istehsali">Karbamid istehsalı sahəsi</option>
  <option value="Ammonyak_istehsali">Ammonyak istehsalı sahəsi</option>
  <option value="Komekci_qurgular">Köməkçi qurğular sahəsi</option>
  <option value="Temir_texniki_xidmet">Təmir və texniki xidmət sahəsi</option>
  <option value="Emtea_anbar">Əmtəə və anbar sahəsi</option>
  <option value="Yardimci_teserrufat">Yardımçı təsərrüfat sahəsi</option>
</select>

</div>

                <div className="mb-4">
                  <label className="block text-gray-700">{t("Vəzifə")}</label>
                  <input
                    type="text"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Vəzifə"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">{t("Daxili nömrə")}</label>
                  <input
                    type="number"
                    value={innerNumber}
                    onChange={(e) => setİnnerNumber(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Daxili nömrə"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Mobil nömrə")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Mobil nömrə"
                    required
                  />
                </div>
                <div className="mb-4">
  <label className="block text-gray-700 mb-2">
    {t("Qazi və ya şəhid ailəsindənsiniz?")}
  </label>

  <div className="flex items-center mb-2">
    <input
      type="checkbox" // Change to checkbox to allow unchecking
      id="qaziYes"
      name="qaziAilesi"
      value="1"
      checked={isMartyr === "1"}
      onChange={(e) => setIsMartyr(e.target.checked ? "1" : "0")} // Set to "1" if checked, empty string if unchecked
      className="mr-2"
    />
    <label htmlFor="qaziYes" className="text-gray-700">
      {t("Bəli")}
    </label>
  </div>
</div>

                {isMartyr === "1" && (
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      {t("Təsdiq edən sənəd")} <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      onChange={handlemartyrDocumentChange}
                      className="w-full p-2 border rounded-md"
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Şifrə")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şifrə"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Şifrə təsdiqi")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şifrə təsdiqi"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-[#223555] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    {t("Göndər")}
                  </button>
                  <p style={{fontSize:'18px', marginTop:'15px'}}>
                    {t("Hesabınız var?")} <a style={{color:'#223555', fontWeight:'600'}} href="/login">{t("Giriş")}</a>
                  </p>
                </div>
              </form>
              </div>
    </>
  );
}
