import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import { useTranslation } from "react-i18next";

export const price = [
  {
    name: 'Solmaz Kazımova',
    title: 'Üzv',
    image: "/resource/team-image17.jpg",
    phone: '+994508432011',
    email: 'solmaz.kazimova@socar.az',
    hid: '32011'
  },
  {
    name: 'Əhmədov Qurban',
    title: 'Üzv',
    image: "/resource/team-image3.jpg",
    phone: '+994508432136',
    email: 'qurban.ahmedov@socar.az',
    hid: '32136'
  },
  {
    name: 'Sabir Əliyev',
    title: 'Üzv',
    image: "/resource/team-image4.jpg",
    phone: '+994508432129',
    email: 'sabir.f.aliyev@socar.az',
    hid: '32129'
  },
  {
    name: 'Nərmin Məmmədov',
    title: 'Üzv',
    image: "/resource/team-image5.jpg",
    phone: '+994508432093',
    email: 'nermin.e.memmedova@socar.az',
    hid: '32093'
  },
  {
    name: 'Ramiz Qədirov',
    title: 'Üzv',
    image: "/resource/team-image6.jpg",
    phone: '+994508432108',
    email: 'ramiz.qadirov@socar.az',
    hid: '32108'
  },
  {
    name: 'Səidə Dadaşova',
    title: 'Üzv',
    image: "/resource/team-image9.jpg",
    phone: '+994508432452',
    email: 'saida.i.dadashova@socar.az',
    hid: '32452'
  },
  {
    name: 'Nəsimi Quliyev',
    title: 'Üzv',
    image: "/resource/team-image10.jpg",
    phone: '+994508432135',
    email: 'nasimi.quliyev@socar.az',
    hid: '32135'
  },
  {
    name: 'Samir Kərimov',
    title: 'Üzv',
    image: "/resource/team-image11.jpg",
    phone: '+994508432078',
    email: 'samir.a.karimov@socar.az',
    hid: '32078'
  },
  {
    name: 'Həcər Muradova',
    title: 'Üzv',
    image: "/resource/team-image-problem.jpg",
    phone: '+994508432106',
    email: 'hecer.a.muradova@socar.az',
    hid: '32106'
  },
  {
    name: 'Əfsanə Sayılova',
    title: 'Üzv',
    image: "/resource/team-image15.jpg",
    phone: '+994508432199',
    email: 'efsane.sayilova@socar.az',
    hid: '32199'
  },
  {
    name: 'Elşən Mehdiyev',
    title: 'Üzv',
    image: "/resource/team-image19.png",
    phone: '+994508432123',
    email: 'elshan.k.medhiyev@socar.az',
    hid: '32123'
  },


];

export const price2 = [

  {
    name: 'Qurban Qurbanov',
    title: 'Xəzinədar',
    image: "/resource/team-image7.jpg",
    phone: '+994508432091',
    email: 'qurban.a.qurbanov@socar.az',
    hid: '32091'
  },
  {
    name: 'Sənan Səfərov',
    title: 'Biznes Tərəfdaş',
    image: "/resource/team-image8.jpg",
    phone: '+994508432113',
    email: 'senan.seferov@socar.az',
    hid: '32113'
  },
  {
    name: 'Səidə Abdullayeva',
    title: 'Biletlər və göndərişlər üzrə məsul şəxs',
    image: "/resource/team-image13.jpg",
    phone: '+994508432054',
    email: 'seide.abdullayeva@socar.a',
    hid: '32054'
  },
  {
    name: 'Ramil Bayramov',
    title: 'Biznes Tərəfdaş',
    image: "/resource/team-image14.jpg",
    phone: '+994508432016',
    email: 'ramil.r.bayramov@socar.az',
    hid: '32016'
  },
  {
    name: 'Dilqəm Umbayev',
    title: 'İnformasiya Texnologiyaları üzrə məsul şəxs',
    image: "/resource/team-image1.jpg",
    phone: '+994508432191',
    email: 'dilqem.umbayev@socar.az',
    hid: '32191'
  },
  {
    name: 'Həmidə Xasanova',
    title: 'Kommunikasiya və tədbirlər üzrə məsul şəxs',
    image: "/resource/team-image16.jpg",
    phone: '',
    email: '',
    hid: ''
  },
  {
    name: 'Kubra Ağayeva',
    title: 'İcraçı komanda',
    image: "/resource/team-image18.png",
    phone: '+994508432137',
    email: 'kubra.agayeva@socar.az',
    hid: '32137'
  },

];

export default function Team() {



  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Komandamız")} title={t("Əsas")} subtitle={t("Komandamız")} />

      <div>
          <p className="text-[22px] md:text-3xl text-center font-semibold mt-10 mb-10">{t("Komitə üzvləri")}</p>
        </div>
       <div className="md:max-w-container max-w-containerSm mx-auto">
       <div className="mx-auto md:w-1/5 mb-5"> 
               <div>
                 <figure>
                   <a href="mailto:mail@email.com">
                     <img src='/resource/team-image2.jpg' alt=""/>
                   </a>
                 </figure>
                 <div>
                   <p className='text-lg mt-2'>Şadman Əmirxanlı</p>
                   <div className="text-[#B89B5E]">{t('Sədr')}</div>
                   <hr />
                 </div>
                 <div>
                   <ul>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2 mt-4"></span> 
                       <a href="#">+994508432031</a>
                     </li>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2"></span> 
                       <a href="#">32031</a>
                     </li>
                     <li>
                       <span className="fa-solid fa-envelope text-[#B89B5E] mr-2"></span> 
                       <a href="#">shadman.amirxanli@socar.az</a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
       </div>
        <div className='md:max-w-container max-w-containerSm mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4'>
      {price.map((data, index) => (
               <div> 
               <div>
                 <figure>
                   <a href="mailto:mail@email.com">
                     <img src={data.image} alt=""/>
                   </a>
                 </figure>
                 <div>
                   <p className='text-lg mt-2'>{data.name}</p>
                   <div className="text-[#B89B5E]">{t(data.title)}</div>
                   <hr />
                 </div>
                 <div>
                   <ul>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2 mt-4"></span> 
                       <a href="#">{data.phone}</a>
                     </li>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2"></span> 
                       <a href="#">{data.hid}</a>
                     </li>
                     <li>
                       <span className="fa-solid fa-envelope text-[#B89B5E] mr-2"></span> 
                       <a href="#">{data.email}</a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
             
            ))}
            </div>


            <div>
          <p className="md:text-3xl text-[22px] text-center font-semibold mt-20 mb-10">{t("İcraçı komanda")}</p>
        </div>
       <div className="md:max-w-container max-w-containerSm mx-auto">
       <div className="mx-auto md:w-1/5 mb-5"> 
               <div>
                 <figure>
                   <a href="mailto:mail@email.com">
                     <img src='/resource/team-image2.jpg' alt=""/>
                   </a>
                 </figure>
                 <div>
                   <p className='text-lg mt-2'>Şadman Əmirxanlı</p>
                   <div className="text-[#B89B5E]">{t('Sədr')}</div>
                   <hr />
                 </div>
                 <div>
                   <ul>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2 mt-4"></span> 
                       <a href="#">+994508432031</a>
                     </li>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2"></span> 
                       <a href="#">32031</a>
                     </li>
                     <li>
                       <span className="fa-solid fa-envelope text-[#B89B5E] mr-2"></span> 
                       <a href="#">shadman.amirxanli@socar.az</a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
       </div>
        <div className='md:max-w-container max-w-containerSm mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-10 md:mb-0'>
      {price2.map((data, index) => (
               <div> 
               <div>
                 <figure>
                   <a href="mailto:mail@email.com">
                     <img src={data.image} alt=""/>
                   </a>
                 </figure>
                 <div>
                   <p className='text-lg mt-2'>{data.name}</p>
                   <div className="text-[#B89B5E]">{t(data.title)}</div>
                   <hr />
                 </div>
                 <div>
                   <ul>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2 mt-4"></span> 
                       <a href="#">{data.phone}</a>
                     </li>
                     <li>
                       <span className="icon fa fa-phone text-[#B89B5E] mr-2"></span> 
                       <a href="#">{data.hid}</a>
                     </li>
                     <li>
                       <span className="fa-solid fa-envelope text-[#B89B5E] mr-2"></span> 
                       <a href="#">{data.email}</a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
             
            ))}
            </div>
    </>
  );
}
