import React from "react";
import Heading from "../../home/Heading";
import { useTranslation } from "react-i18next";
import { news } from "../../../assets";

const newsData = [
  {
    id: 1,
    imgSrc: news,
    date: "21 Aug",
    tags: ["Criminal Law", "Kidnapping"],
    title: "We won against criminal",
    description: "In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.",
    link: "/news/1"
  },
  {
    id: 2,
    imgSrc: news,
    date: "15 Sep",
    tags: ["Corporate Law", "Fraud"],
    title: "Corporate fraud case won",
    description: "Corporate fraud is an illegal, dishonest activity perpetrated by individuals or companies in order to provide an advantageous financial outcome to the individual.",
    link: "/news/2"
  },
  {
    id: 1,
    imgSrc: news,
    date: "21 Aug",
    tags: ["Criminal Law", "Kidnapping"],
    title: "We won against criminal",
    description: "In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.",
    link: "/news/1"
  },
];

export default function CarouselMobile() {

  

  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="mx-auto mt-5 md:mt-20">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {newsData.map((news) => (
        <article key={news.id} className="border p-4 rounded-lg shadow-md">
          <div className="relative">
            <img src={news.imgSrc} alt={news.title} className="w-full h-auto rounded-md" />
            <a href={news.link} className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white">
              <span className="fa fa-link text-2xl"></span>
            </a>
          </div>
          <div className="mt-4">
            <div className="text-lg font-semibold">{news.date}</div>
            <div className="mt-2 text-sm text-gray-500">
              <span className="fa fa-tags mr-2"></span>
              {news.tags.map((tag, index) => (
                <span key={index}>
                  <a href="#" className="text-blue-600">{tag}</a>{index < news.tags.length - 1 && ', '}
                </span>
              ))}
            </div>
            <h3 className="mt-2 text-xl font-bold text-gray-800">{news.title}</h3>
            <p className="mt-2 text-gray-600">{news.description}</p>
            <a href={news.link} className="mt-4 inline-block text-blue-600 hover:underline">Daha çox</a>
          </div>
        </article>
      ))}
    </div>
      </div>
    </>
  );
}
