import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import Swal from "sweetalert2";

export default function Dashboard() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const user = localStorage.getItem("user");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() { 
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/applicat.php/", {
        withCredentials: false,
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .then(function (response) {
        setProducts(response.data);
      });
  }


  const deleteUser = (id) => {
    Swal.fire({
      title: 'Silmək istədiyinizə əminsiniz?',
      text: "Müraciət geri qaytarıla bilməyəcək!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Bəli, sil!',
      cancelButtonText: 'Xeyr, ləğv et'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
      .delete(`https://www.hemkarbamid.az/hemkarbamid/applicat.php/${id}/delete`, {
        withCredentials: false,
      })
          .then(function (response) {
            getProducts(); // Kullanıcı silindiğinde ürünleri yenile
            Swal.fire(
              'Silindi!',
              'Müraciət uğurla silindi.',
              'success'
            );
          })
          .catch((error) => {
            Swal.fire(
              'Hata!',
              'Kullanıcı silinirken bir hata oluştu.',
              'error'
            );
          });
      }
    });
  };

  const uploadMartyrDocument = (id) => {
    const formData = new FormData();
    const input = document.getElementById(`martyrDocumentInput-${id}`);
    if (input.files.length > 0) {
      formData.append('martyrDocument', input.files[0]);

      axios
        .post(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          alert(response.data.message);
          getProducts(); // Yenile
        })
        .catch(error => {
          console.error("There was an error uploading the file!", error);
        });
    } else {
      alert("Lütfen bir dosya seçin.");
    }
  };

  const downloadFile = (base64String, fileType, fileName) => {
    const link = document.createElement('a');
    link.href = `data:${fileType};base64,${base64String}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <>
    <DashboardHeader />
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container"></div>
    </nav>
    <div className="container" style={{ paddingTop: 50 }}>
      <div>
        <table>
          <thead>
            <tr>
              <th>Actions</th>
              <th>ID</th>
              <th>User Id</th>
              <th>Əsas kateqoriya</th>
              <th>İkinci kateqoriya</th>
              <th>Sənəd 1</th>
              <th>Sənəd 2</th>
              <th>Sənəd 3</th>
              <th>Tarix</th>
              
            </tr>
          </thead>
          <tbody>
            {products.map((user, key) => (
              <tr key={key}>
                <td>
                  <button className="p-2 bg-[red] text-[white] rounded" onClick={() => deleteUser(user.id)}>Sil</button>
                </td>
                <td>{user.id}</td>
                <td>{user.userId}</td>
                <td>{user.mainCategory}</td>
                <td>{user.secondCategory}</td>
                <td>
                    {user.document1 ? (
                      <button onClick={() => downloadFile(user.document1, 'application/octet-stream', 'document1')} >Yüklə</button>
                    ) : (
                      'Yoxdur'
                    )}
                  </td>
                  <td>
                    {user.document2 ? (
                      <button onClick={() => downloadFile(user.document2, 'application/octet-stream', 'document2')} >Yüklə</button>
                    ) : (
                      'Yoxdur'
                    )}
                  </td>
                  <td>
                    {user.document3 ? (
                      <button onClick={() => downloadFile(user.document3, 'application/octet-stream', 'document3')} >Yüklə</button>
                    ) : (
                      'Yoxdur'
                    )}
                  </td>
                <td>{user.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
  );
}



