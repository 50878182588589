import React from 'react';
import Partners from '../../Components/home/Partners/Partners';
import Team from '../../Components/home/Team/Team';
import Hero from '../../Components/Hero/Hero';



const Home = () => {
  return (
    <>
   <div className='bg-[#F3F3F3]'> 
   <Hero/>
    <Team/> 
    <Partners/>
   </div>
    
    </>
  )
}

export default Home;
