import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { footerLogo, logo, logoFooter, logoLeft, mpost } from "../../assets/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { login, logout } from '../../Components/redux/authSlice';


const Header = () => {

  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(null); // Sadece bir menüyü açık tutmak için state
  const timeoutRef = useRef(null);
  const userId = useSelector((state) => state.auth.userId); // Redux'dan userId'yi al
  const member = useSelector((state) => state.auth.member); // Redux'dan userId'yi al
  const [isInputVisible, setInputVisible] = useState(false);

  const toggleInput = () => {
    setInputVisible(!isInputVisible);
  };


  // Menü aç/kapat fonksiyonu
  const toggleMenu = (menuName) => {
    setOpenMenu((prevMenu) => (prevMenu === menuName ? null : menuName)); // Aynı menüyü tıklarsak kapatır
  };

  // Hover ile menü açma fonksiyonu
  const openMenuOnHover = (menuName) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setOpenMenu(menuName);
  };

  // Hover ile menü kapama fonksiyonu
  const closeMenuOnHover = () => {
    timeoutRef.current = setTimeout(() => {
      setOpenMenu(null);
    }, 200);
  };
  

  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isServicesOpen2, setIsServicesOpen2] = useState(false);
  const [isServicesOpen3, setIsServicesOpen3] = useState(false);
  const [isServicesOpen4, setIsServicesOpen4] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);
  

    // Hamburger menüsünü aç/kapa
    const toggleHamburgerMenu = () => {
        setIsHamburgerOpen(!isHamburgerOpen);
      };


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };
  const openServicesMenu2 = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen2(true);
  };

  const openServicesMenu3 = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen3(true);
  };

  const openServicesMenu4 = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen4(true);
  };



  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const closeServicesMenu2 = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen2(false);
    }, 200);
  };
  const closeServicesMenu3 = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen3(false);
    }, 200);
  };
  const closeServicesMenu4 = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen4(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const navigate = useNavigate()
  const dispatch = useDispatch()

  
  const handleLogout = () => {
    Swal.fire({
      title: 'Əminsiz?',
      text: "Hesabınızdan çıxmaq istəyirsiz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bəli',
      cancelButtonText: 'Xeyr'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        navigate("/");
        toast.error("Hesabınızdan çıxıldı")
      }
    });
  };

  return (
    <>
   <nav className=" border-b text-[white] border-gray-200  h-[53px] hidden md:block">
        <div className="max-w-container mx-auto">
          <div className="container mx-auto flex items-center justify-between h-[53px] "> 
          <div>
          <div className="flex items-center relative ml-auto mr-10 "> 
      <button className={`focus:outline-none h-[53px] px-3 hover:bg-[#0086C9] hover:text-[white] ${isHamburgerOpen ? 'bg-[#0086C9] text-[white] ' : 'text-[black]'}`} onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars "></i> 
      </button>
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 bg-[#0086C9] text-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}>
         <div className="pt-10">
        <div className="max-w-container mx-auto">
        <div className="flex justify-between">
        <div>
              <a href='/'> <img src={logoLeft} alt="Logo" className="h-20" /></a>
            </div> 
        <button className="text-[20px] border-[white] border-[1px] p-3 rounded-md h-14 "  onClick={toggleHamburgerMenu}>
            <FaTimes  />
          </button>
          </div>
          </div>
          <hr className="mt-5"></hr>
          <div className="max-w-container mx-auto">
          <div className="grid grid-cols-4">
          <div className="flex flex-col items-start pt-8">
            <a href="/" className="block px-4 py-2 text-[white] ">{t("Əsas səhifə")}</a>
            <a href="/about" className="block px-4 py-2 text-[white] ">{t("Haqqımızda")}</a>
            <a href="/team" className="block px-4 py-2 text-[white] ">{t("Komandamız")}</a>
            <a href="/partners" className="block px-4 py-2 text-[white]">{t("Tərəfdaşlarımız")}</a>
            <a href="/faq" className="block px-4 py-2 text-[white] ">{t("FAQ")}</a>
          </div>
          <div className="flex flex-col items-start pt-8">
            <a href="/news" className="block px-4 py-2 text-[white] ">{t("Xəbərlər")}</a>
            <a href="/events" className="block px-4 py-2 text-[white]">{t("Tədbirlər")}</a>
            <a href="/statistics" className="block px-4 py-2 text-[white] ">{t("Statistika")}</a>
          </div>
          <div className="flex flex-col items-start pt-8 ">
            <a href="/documents" className="block px-4 py-2 text-[white]">{t("Sənədlər")}</a>
            <a href="/laws" className="block px-4 py-2 text-[white]">{t("Qanunvericilik")}</a>
            <a href="/offers" className="block px-4 py-2 text-[white]">{t("Rəy və təklif")}</a>
          </div>
          <div className="flex flex-col items-start pt-8">
            <a href="/contact" className="block px-4 py-2 text-[white]">{t("Əlaqə")}</a>
            <a href="/register" className="block px-4 py-2 text-[white]">{t("Qeydiyyat")}</a>
            <a href="/login" className="block px-4 py-2 text-[white]">{t("Giriş")}</a>
          </div>
          </div>
          </div>
          </div>
        </div>
      )}
    </div> 
            </div>  
            <div className="hidden space-x-2 text-[10px] xl:text-[15px] 2xl:text-base 3xl:text-[17px] lg:flex gap-6 mr-20 ">
            <a href="/" 
          className="text-black font-bold text-base group relative cursor-pointer"
        >
          {t("Əsas səhifə")}
          <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
        </a>
          
            {/* Haqqımızda menüsü */}
            <div
              className="relative flex items-center"
              onMouseEnter={() => openMenuOnHover("about")}
              onMouseLeave={closeMenuOnHover}
            >
              <button className="font-bold focus:outline-none text-black flex items-center group relative cursor-pointer ">
                {t("Haqqımızda")}
                <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
                <FaChevronDown className="ml-2" />
              </button>
              {openMenu === "about" && (
                <div className="absolute top-full left-0 mt-2 bg-white text-black border border-gray-200 shadow-lg z-20 w-48">
                  <a href="/about" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("Məlumat")}</a>
                  <a href="/team" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("Komandamız")}</a>
                  <a href="/partners" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("Tərəfdaşlarımız")}</a>
                  <a href="/faq" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("FAQ")}</a>
                </div>
              )} 
            </div>

            {/* Xidmətlər menüsü */}
            <div
              className="relative flex items-center"
              onMouseEnter={() => openMenuOnHover("services")}
              onMouseLeave={closeMenuOnHover}
            >
              <button className="font-bold focus:outline-none text-black flex items-center group relative cursor-pointer ">
                {t("Xidmətlər")}
                <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
                <FaChevronDown className="ml-2" />
              </button>
              {openMenu === "services" && (
                <div className="absolute top-full left-0 mt-2 bg-white text-black border border-gray-200 shadow-lg z-20 w-48">
                  <a href="/events" className="block px-4 py-2 hover:bg-[#E53037] hover:text-[white]  ">{t("Tədbirlər")}</a>
                </div>
              )}
            </div>

            {/* İşlərimiz menüsü */}
            <div
              className="relative flex items-center"
              onMouseEnter={() => openMenuOnHover("works")}
              onMouseLeave={closeMenuOnHover}
            >
              <button className="font-bold focus:outline-none text-black flex items-center group relative cursor-pointer ">
                {t("İşlərimiz")}
                <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
                <FaChevronDown className="ml-2" />
              </button>
              {openMenu === "works" && (
                <div className="absolute top-full left-0 mt-2 bg-white text-black border border-gray-200 shadow-lg z-20 w-48">
                  <a href="/news" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white group relative cursor-pointer">{t("Xəbərlər")}</a>
                  <a href="/statistics" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("Statistika")}</a>
                </div>
              )}
            </div>

            {/* Sənədlər menüsü */}
            <div
              className="relative flex items-center"
              onMouseEnter={() => openMenuOnHover("documents")}
              onMouseLeave={closeMenuOnHover}
            >
              <button className="font-bold focus:outline-none text-black flex items-center group relative cursor-pointer  ">
                {t("Sənədlər")}
                <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
                <FaChevronDown className="ml-2" />
              </button>
              {openMenu === "documents" && (
                <div className="absolute top-full left-0 mt-2 bg-white text-black border border-gray-200 shadow-lg z-20 w-48">
                  <a href="/laws" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("Qanunvericilik")}</a>
                  <a href="/documents" className="block px-4 py-2 hover:bg-[#E53037] hover:text-white">{t("Sənəd nümunələri")}</a>
                </div>
              )}
            </div>

            <a
  href="/offers"
  className="font-bold text-black group relative cursor-pointer "
>
  {t("Rəy və təklif")}
  <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
</a>
<a
  href="/contact"
  className="font-bold text-black group relative cursor-pointer  "
  
>
  {t("Əlaqə")}

  <span className="absolute left-0 -bottom-1 w-0 h-[2px] bg-[#E53037] transition-all duration-300 group-hover:w-full"></span>
</a>

          </div>
            <div className="lg:hidden flex items-center relative ml-auto mr-10"> 
      <button className="text-[#white] focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[black]"></i>
      </button>
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-[390px] z-50 shadow-lg`}>
          <button className="absolute top-5 right-5 text-white bg-[#white] p-2 rounded" onClick={toggleHamburgerMenu}>
            <FaTimes />
          </button>
          <div className="flex flex-col items-start p-8">
            <a href="/" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Əsas səhifə")}</a>
            <a href="/about" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Haqqımızda")}</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="/services" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Xidmətlərimiz")}</a>
                <button
                  onClick={toggleServicesSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div> 
              {isServicesOpen && (
                <div className="ml-4">
                  <a href="/services" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Bütün xidmətlər")}</a>
                  <a href="/muhasibatliq" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Mühasibatlıq")}</a>
                  <a href="/maliyye" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Maliyyə")}</a>
                  <a href="/vergi-ucotu" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Vergi uçotu")}</a>
                  <a href="/insan-resurslari" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("İnsan resursları")}</a>
                  <a href="/proqramlasdirma" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Proqramlaşdırma")}</a>
                  <a href="/e-kassa" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("E-kassa")}</a>
                  <a href="/sebeke-idareetmesi" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Şəbəkə idareetmesi")}</a>
                </div>
              )}
            </div>
            <a href="/partners" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Müştərilərimiz")}</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="/products" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Məhsullar")}</a>
                <button
                  onClick={toggleProductsSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div>
              {isProductsOpen && (
                <div className="ml-4">
                  <a href="/products" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Bütün məhsullar")}</a>
                  <a href="/pul-qutulari" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Pul qutuları")}</a>
                  <a href="/barkod-processor" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Barkod Prosessor")}</a>
                  <a href="/barkod-oxuyucu" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Barkod oxuyucu")}</a>
                  <a href="/card-reader" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Card reader")}</a>
                  <a href="/data-terminal" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Data terminal")}</a>
                  <a href="/pos-printer" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Pos printer")}</a>
                  <a href="/barkod-terezi" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Barkod Tərəzi")}</a>
                </div>
              )}
            </div>
            <a href="/program-software" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Proqram təminatı")}</a>
            <a href="/documents" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Ərizə formaları")}</a>
            <a href="/contact" className="block px-4 py-2 text-[#white] hover:bg-gray-100">{t("Əlaqə")}</a>
            <a href='/assets/documents/Mpost.docx' download>
              <button className="bg-[white] text-[#223555] text-xs px-1 py-2 rounded font-bold scaling-button ml-3">
              {t("Ödənişsiz yükləyin")}
              </button>
            </a>
          </div>
        </div>
      )}
    </div> 
    {
  userId ? (
    <div className="hidden sml:block">  
        <button onClick={handleLogout} className="bg-[#0086C9] text-[white] w-[100px] py-2 mr-2 rounded font-bold ">
          {t(member)}
        </button>
    </div>
  ) : (

    <div className="hidden sml:block">  
    <a href='/register'>
      <button className="bg-[#0086C9] text-[white] w-[100px] py-2 mr-2 rounded font-bold ">
        {t("Qeydiyyat")}
      </button>
    </a>
    <a href='/login'>
      <button className="bg-[#0086C9] text-[white] w-[100px] py-2 rounded font-bold ">
        {t("Giriş")}
      </button>
    </a>
  </div>
  )
}


          </div>
        </div>
      </nav>   

      {/* Hamburger menüsü */}
      {isHamburgerOpen && ( 
        <div className="absolute top-[230px] left-0 w-full h-[500px] z-20 ">
        <div className="p-4 max-w-container mx-auto bg-white flex justify-between shadow-md font-semibold">
  <div className="flex-1  border-r border-gray-300">
    <a href="/" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Əsas səhifə")}</a>
    <a href="/about" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Haqqımızda")}</a>
    <a href="/team" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Komandamız")}</a>
    <a href="/partners" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Tərəfdaşlarımız")}</a>
  </div>
  <div className="flex-1 border-r border-gray-300">
    <a href="/faq" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("FAQ")}</a>
    <a href="/events" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Tədbirlər")}</a>
    <a href="/news" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Xəbərlər")}</a>
  </div>
  <div className="flex-1 border-r border-gray-300">
    <a href="/laws" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Qanunvericilik")}</a>
    <a href="/offers" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Rəy və təklif")}</a>
  </div>
  <div className="flex-1">
    <a href="/documents" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Sənədlər")}</a>
    <a href="/statistics" className="block px-4 py-2 text-black hover:bg-[#007bb5] hover:text-white">{t("Statistika")}</a>
  </div>
  <div className="ml-4">
      <a href="#" onClick={toggleInput} className="p-2 text-black">
        <i className="fas fa-search"></i>
      </a>
      {isInputVisible && (
        <input
          type="text"
          placeholder="Axtaran tapar..."
          className="ml-2 p-1 text-[black] border-2 border-[gray] rounded"
          onBlur={() => setInputVisible(false)} // Input dışında bir yere tıklanırsa inputu kapat
        />
      )}
    </div>
</div>

        </div>
      )}
    </>
  ); 
};

export default Header;
































































