import React, { useEffect, useState } from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { login } from '../../Components/redux/authSlice';

export default function Login() { 

  const { t, i18n } = useTranslation();

  const [password, setPassword] = useState("");
  const [finKod, setFinKod] = useState("");
  const [members, setMembers] = useState([]); // Tüm üyeleri burada saklayacağız
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Component mount olduğunda kullanıcıları GET ile al
  useEffect(() => {
    getMembers();
  }, []);

  function getMembers() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", {
        withCredentials: false,
      })
      .then(function (response) {
        // Filtreleme işlemi burada yapılıyor
        const filteredMembers = response.data.filter(member => member.confirm == 1);
        setMembers(filteredMembers);
      }); 
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Formun varsayılan gönderimini engelleyin

    // Girilen hidNumber ve password'ü kontrol et
    const user = members?.find(
      (member) => member.finKod === finKod && member.password === password
    );

    if (user) {
      // Giriş başarılıysa
      toast.success("Uğurlu giriş!");
      dispatch(login({ firstName: user.name, lastName: user.surname, department: user.department_id, userId: user.id }));
      navigate("/"); // Anasayfaya yönlendirin
    } else {
      // Giriş bilgileri hatalıysa
      toast.error("FIN kod və ya şifrə səhvdir.");
    }
  };
  return (
    <>
      <Heading heading={t("Giriş")} title={t("Əsas")} subtitle={t("Giriş")} />
      <div className="p-6 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700">
                   {t("Şəxsiyyət vəsiqəsi FİN Kod")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={finKod}
                    onChange={(e) => setFinKod(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="FİN Kod"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Şifrə")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şifrə"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-[#223555] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    {t("Giriş")}
                  </button>
                </div>
              </form>
            </div>
    </>
  );
}
