import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './Carousel.css';
import { useTranslation } from "react-i18next";
import { news, oktay } from "../../../assets";

const Carousel = () => {
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: oktay, title: 'Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir.', text: "SOCAR-ın yaydığı məlumata görə, ölkə başçısına bildirilmişdir ki, SOCAR son illərdə əməliyyatların dayanıqlığını təmin etmək və bir əmtəə növündən asılılığını azaltmaq məqsədi ilə neft-qaz və qaz-kimya sektorunu genişləndirmək xətti götürüb. Karbamid zavodu planlı və mərhələli şəkildə ərsəyə gətirilən böyük dəyər zəncirinin bir hissəsidir. Zavod il ərzində 435 milyon m3 təbii qaz həcmindən xammal kimi istifadə etməklə, 650-660 min ton karbamid məhsulu istehsal etmək gücündədir." },
    { img: oktay, title: 'Yüksək azot tutumuna malik karbamid gübrəsinin istehsalı Azərbaycanın kənd təsərrüfatının inkişafına mühüm töhfə verəcək', text: "SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır." },
    { img: oktay, title: 'Azərbaycan Respublikası Dövlət Neft Şirkətinin “Karbamid” Zavoduna direktor təyin edilib.', text: "Banker.az-ın əldə etdiyi məlumata görə, zavoda 2023-cü ilin üçüncü rübündən etibarən türkiyəli mütəxəssis Kutay Durna rəhbərlik edəcək. O, bu vəzifədə Xəyal Cəfərov Vilayət oğlunu əvəz edib. Xəyal Cəfərov Azərbaycan Dövlət Neft Şirkətinin (SOCAR) Heydər Əliyev adına Neft Emalı Zavodunun direktoru vəzifəsinə təyin olunub." },
    { img: oktay, title: 'Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir.', text: "SOCAR-ın yaydığı məlumata görə, ölkə başçısına bildirilmişdir ki, SOCAR son illərdə əməliyyatların dayanıqlığını təmin etmək və bir əmtəə növündən asılılığını azaltmaq məqsədi ilə neft-qaz və qaz-kimya sektorunu genişləndirmək xətti götürüb. Karbamid zavodu planlı və mərhələli şəkildə ərsəyə gətirilən böyük dəyər zəncirinin bir hissəsidir. Zavod il ərzində 435 milyon m3 təbii qaz həcmindən xammal kimi istifadə etməklə, 650-660 min ton karbamid məhsulu istehsal etmək gücündədir." },
    { img: oktay, title: 'Yüksək azot tutumuna malik karbamid gübrəsinin istehsalı Azərbaycanın kənd təsərrüfatının inkişafına mühüm töhfə verəcək', text: "SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır." },
    { img: oktay, title: 'Azərbaycan Respublikası Dövlət Neft Şirkətinin “Karbamid” Zavoduna direktor təyin edilib.', text: "Banker.az-ın əldə etdiyi məlumata görə, zavoda 2023-cü ilin üçüncü rübündən etibarən türkiyəli mütəxəssis Kutay Durna rəhbərlik edəcək. O, bu vəzifədə Xəyal Cəfərov Vilayət oğlunu əvəz edib. Xəyal Cəfərov Azərbaycan Dövlət Neft Şirkətinin (SOCAR) Heydər Əliyev adına Neft Emalı Zavodunun direktoru vəzifəsinə təyin olunub." },
    { img: oktay, title: 'Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir.', text: "SOCAR-ın yaydığı məlumata görə, ölkə başçısına bildirilmişdir ki, SOCAR son illərdə əməliyyatların dayanıqlığını təmin etmək və bir əmtəə növündən asılılığını azaltmaq məqsədi ilə neft-qaz və qaz-kimya sektorunu genişləndirmək xətti götürüb. Karbamid zavodu planlı və mərhələli şəkildə ərsəyə gətirilən böyük dəyər zəncirinin bir hissəsidir. Zavod il ərzində 435 milyon m3 təbii qaz həcmindən xammal kimi istifadə etməklə, 650-660 min ton karbamid məhsulu istehsal etmək gücündədir." },
    { img: oktay, title: 'Yüksək azot tutumuna malik karbamid gübrəsinin istehsalı Azərbaycanın kənd təsərrüfatının inkişafına mühüm töhfə verəcək', text: "SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır." },
    { img: oktay, title: 'Azərbaycan Respublikası Dövlət Neft Şirkətinin “Karbamid” Zavoduna direktor təyin edilib.', text: "Banker.az-ın əldə etdiyi məlumata görə, zavoda 2023-cü ilin üçüncü rübündən etibarən türkiyəli mütəxəssis Kutay Durna rəhbərlik edəcək. O, bu vəzifədə Xəyal Cəfərov Vilayət oğlunu əvəz edib. Xəyal Cəfərov Azərbaycan Dövlət Neft Şirkətinin (SOCAR) Heydər Əliyev adına Neft Emalı Zavodunun direktoru vəzifəsinə təyin olunub." },
    { img: oktay, title: 'Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir.', text: "SOCAR-ın yaydığı məlumata görə, ölkə başçısına bildirilmişdir ki, SOCAR son illərdə əməliyyatların dayanıqlığını təmin etmək və bir əmtəə növündən asılılığını azaltmaq məqsədi ilə neft-qaz və qaz-kimya sektorunu genişləndirmək xətti götürüb. Karbamid zavodu planlı və mərhələli şəkildə ərsəyə gətirilən böyük dəyər zəncirinin bir hissəsidir. Zavod il ərzində 435 milyon m3 təbii qaz həcmindən xammal kimi istifadə etməklə, 650-660 min ton karbamid məhsulu istehsal etmək gücündədir." },
  ]; 

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500); // Show text after 0.5 seconds

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500); // Show button after 1.5 seconds

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000, // Delay before auto-slide
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}">${index + 1}</span>`;
        }
      }}
      navigation={true}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false);
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="slide-content relative flex justify-between h-[400px]">
            <div className="md:w-1/2 h-[400px] w-full">
              <img src={slide.img} alt={`Slide ${index + 1}`} className="w-[200px] h-[400px] object-cover" />
            </div>
            <div className="md:w-1/2">
              <div className="flex items-center justify-center bg-[white] flex-1 h-[400px]">
                <div className="w-[80%] mx-auto text-center flex-col justify-between items-end">
                  <h1 className="font-bold mb-4 text-lg">{t(slide.title)}</h1>
                  <p className="mb-6 text-xs">{t(slide.text)}</p>
                  <a href="/news">
                    <button className="bg-[#0086C9] rounded-xl text-white py-3 px-8 hover:bg-gray-800">
                      {t("BÜTÜN XƏBƏRLƏR")}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;






















