import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import Swal from "sweetalert2";

export default function DashboardOffers() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showFiltered, setShowFiltered] = useState(true); // State for toggling visibility

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/offers.php/", {
        withCredentials: false,
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .then(function (response) {
        const filteredProducts = response.data.filter(product => product.visible == 1);
        console.log(filteredProducts);
        setFilteredProducts(filteredProducts);
        setProducts(response.data);
      });
  }

  const deleteUser = (id) => {
    Swal.fire({
      title: 'Silmək istədiyinizə əminsiniz?',
      text: "Təklif geri qaytarıla bilməyəcək!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Bəli, sil!',
      cancelButtonText: 'Xeyr, ləğv et'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://www.hemkarbamid.az/hemkarbamid/offers.php/${id}/delete`, {
            withCredentials: false,
          })
          .then(function (response) {
            getProducts();
            Swal.fire('Silindi!', 'Təklif uğurla silindi.', 'success');
          })
          .catch((error) => {
            Swal.fire('Hata!', 'Kullanıcı silinirken bir hata oluştu.', 'error');
          });
      }
    });
  };

  const hideUser = (id) => {
    Swal.fire({
      title: 'Təsdiqləmək istədiyinizə əminsiz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Bəli, təsdiqlə!',
      cancelButtonText: 'Xeyr, ləğv et'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`https://www.hemkarbamid.az/hemkarbamid/offers.php/${id}/update`, {
            id: id,
            visible: 0
          }, {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: false
          })
          .then(function (response) {
            console.log("User updated:", response.data);
            getProducts();
            Swal.fire('Təsdiqləndi!', 'Təklif uğurla gizlədildi', 'success');
          })
          .catch(function (error) {
            console.error("Error updating user:", error);
            Swal.fire('Hata!', 'Kullanıcı onaylanırken bir hata oluştu.', 'error');
          });
      }
    });
  };

  const toggleProducts = () => {
    setShowFiltered(!showFiltered);
  };

  return (
    <>
      <DashboardHeader />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <div>
          <button 
            className="mb-4 p-2 bg-blue-500 text-white rounded" 
            onClick={toggleProducts}
          >
            {showFiltered ? 'Hamsını Göstər' : 'Sadəcə Filtrlenmişləri Göstər'}
          </button>

          <table>
            <thead>
              <tr>
                <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Soyad</th>
                <th>Təklif</th>
                <th>Tarix</th>
              </tr>
            </thead>
            <tbody>
              {(showFiltered ? filteredProducts : products).map((user, key) => (
                <tr key={key}>
                  <td>
                    <button className="p-2 bg-[red] text-[white] rounded mr-2" onClick={() => deleteUser(user.id)}>Sil</button>
                    <button className="p-2 bg-[orange] text-[white] rounded" onClick={() => hideUser(user.id)}>Gizlət</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user.offer}</td>
                  <td>{user.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
