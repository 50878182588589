import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './partners.css'
import { useTranslation } from "react-i18next";
import { partners10, partners11, partners12, partners13, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, titleLine } from "../../../assets";

export const price = [
  {
    name: 'Dilqəm Umbayev',
    title: 'İnformasiya Texnologiyaları üzrə məsul şəxs', 
    image: "/resource/team-image1.jpg",
    phone: '+994508432191',
    email: 'dilqem.umbayev@socar.az',
    hid: '32191'
  },
  {
    name: 'Şadman Əmirxanlı',
    title: 'Sədr',
    image: "/resource/team-image2.jpg",
    phone: '+994508432031',
    email: 'shadman.amirxanli@socar.az',
    hid: '32031'
  },
  {
    name: 'Əhmədov Qurban',
    title: 'Üzv',
    image: "/resource/team-image3.jpg",
    phone: '+994508432136',
    email: 'qurban.ahmedov@socar.az',
    hid: '32136'
  },
  {
    name: 'Sabir Əliyev',
    title: 'Üzv',
    image: "/resource/team-image4.jpg",
    phone: '+994508432129',
    email: 'sabir.f.aliyev@socar.az',
    hid: '32129'
  },
  {
    name: 'Nərmin Məmmədov',
    title: 'Üzv',
    image: "/resource/team-image5.jpg",
    phone: '+994508432093',
    email: 'nermin.e.memmedova@socar.az',
    hid: '32093'
  },
  {
    name: 'Ramiz Qədirov',
    title: 'Üzv',
    image: "/resource/team-image6.jpg",
    phone: '+994508432108',
    email: 'ramiz.qadirov@socar.az',
    hid: '32108'
  },
  {
    name: 'Qurban Qurbanov',
    title: 'Xəzinədar',
    image: "/resource/team-image7.jpg",
    phone: '+994508432091',
    email: 'qurban.a.qurbanov@socar.az',
    hid: '32091'
  },
  {
    name: 'Sənan Səfərov',
    title: 'Biznes Tərəfdaş',
    image: "/resource/team-image8.jpg",
    phone: '+994508432113',
    email: 'senan.seferov@socar.az',
    hid: '32113'
  },
  {
    name: 'Səidə Dadaşova',
    title: 'Üzv',
    image: "/resource/team-image9.jpg",
    phone: '+994508432452',
    email: 'saida.i.dadashova@socar.az',
    hid: '32452'
  },
  {
    name: 'Nəsimi Quliyev',
    title: 'Üzv',
    image: "/resource/team-image10.jpg",
    phone: '+994508432135',
    email: 'nasimi.quliyev@socar.az',
    hid: '32135'
  },
  {
    name: 'Samir Kərimov',
    title: 'Üzv',
    image: "/resource/team-image11.jpg",
    phone: '+994508432078',
    email: 'samir.a.karimov@socar.az',
    hid: '32078'
  },
  {
    name: 'Həcər Muradova',
    title: 'Üzv',
    image: "/resource/team-image-problem.jpg",
    phone: '+994508432106',
    email: 'hecer.a.muradova@socar.az',
    hid: '32106'
  },
  {
    name: 'Səidə Abdullayeva',
    title: 'Biletlər və göndərişlər üzrə məsul şəxs',
    image: "/resource/team-image13.jpg",
    phone: '+994508432054',
    email: 'seide.abdullayeva@socar.a',
    hid: '32054'
  },
  {
    name: 'Ramil Bayramov',
    title: 'Biznes Tərəfdaş',
    image: "/resource/team-image14.jpg",
    phone: '+994508432016',
    email: 'ramil.r.bayramov@socar.az',
    hid: '32016'
  },
  {
    name: 'Əfsanə Sayılova',
    title: 'Üzv',
    image: "/resource/team-image15.jpg",
    phone: '+994508432199',
    email: 'efsane.sayilova@socar.az',
    hid: '32199'
  },
  {
    name: 'Həmidə Xasanova',
    title: 'Kommunikasiya və tədbirlər üzrə məsul şəxs',
    image: "/resource/team-image16.jpg",
    phone: '',
    email: '',
    hid: ''
  },
  {
    name: 'Solmaz Kazımova',
    title: 'Üzv',
    image: "/resource/team-image17.jpg",
    phone: '+994508432011',
    email: 'solmaz.kazimova@socar.az',
    hid: '32011'
  },
  {
    name: 'Kubra Ağayeva',
    title: 'İcraçı komanda',
    image: "/resource/team-image18.png",
    phone: '+994508432137',
    email: 'kubra.agayeva@socar.az',
    hid: '32137'
  },
  {
    name: 'Elşən Mehdiyev',
    title: 'Üzv',
    image: "/resource/team-image19.png",
    phone: '+994508432123',
    email: 'elshan.k.medhiyev@socar.az',
    hid: '32123'
  },

];

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className} slick-prev`} onClick={onClick}>
      &#8592; {/* Left arrow icon */}
    </button>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className} slick-next`} onClick={onClick}>
      &#8594; {/* Right arrow icon */}
    </button>
  );
};

const Partners = () => {

  const { t, i18n } = useTranslation();
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="md:max-w-container max-w-containerSm mx-auto mt-20 py-5 md:py-0">
    <h1 className="md:text-3xl text-[22px] font-extrabold text-center mb-5 ">{t("Komandamız")}</h1>
    <img className="w-52 mx-auto mb-10" src={titleLine}></img>
        <Slider {...settings}>

{price.map((data, index) => (
       <div> 
       <div>
         <figure>
           <a href="mailto:mail@email.com">
             <img src={data.image} alt=""/>
           </a>
         </figure>
         <div>
           <p className='text-lg mt-2'>{data.name}</p>
           <div className="text-[#B89B5E]">{t(data.title)}</div>
           <hr />
         </div>
         <div>
           <ul>
             <li className="text-[14px]">
               <span className="icon fa fa-phone text-[#B89B5E] mr-2 mt-4"></span> 
               <a href="#">{data.phone}</a>
             </li>
             <li className="text-[14px]">
               <span className="icon fa fa-phone text-[#B89B5E] mr-2"></span> 
               <a href="#">{data.hid}</a>
             </li>
             <li className="text-[14px]">
               <span className="fa-solid fa-envelope text-[#B89B5E] mr-2"></span> 
               <a href="#">{data.email}</a>
             </li>
           </ul>
         </div>
       </div>
     </div>
     
    ))}

</Slider>
</div>
  );
};

export default Partners;