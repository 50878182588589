// import React, { useEffect } from 'react';
// import { toast } from 'react-toastify';
// import Swal from 'sweetalert2'
// import { useNavigate } from 'react-router-dom';



// const DashboardHeader = () => {

//   const navigate = useNavigate(); 

//   const handleLogout = () => {
//     Swal.fire({
//       title: 'Əminsiz?',
//       text: "Admin paneldən çıxmaq istəyirsiz?",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Bəli',
//       cancelButtonText: 'Xeyr'
//     }).then((result) => {
//       if (result.isConfirmed) {
//         localStorage.setItem("login", "");
//         localStorage.setItem("loginStatus", "Logged out successfully!");
//         navigate("/");
//         toast.error("Admin paneldən çıxıldı")
//       }
//     });
//   };

//   const user = localStorage.getItem("user");




//   return ( 
//     <>
//       <header class="main-header">
//             {/* <!-- Top Bar --> */}
            
//             <div class="middle-bar">
//             	<div class="auto-container">
//                 </div>
//             </div>
            
//             {/* <!-- Lower Section --> */}
//             <div class="lower-section">
//                 <div class="auto-container">
                    
//                     {/* <!--Outer Box--> */}
//                     <div class="outer-box clearfix">
                         
//                         {/* <!-- Hidden Nav Toggler --> */}
//                         <div class="nav-toggler">
//                         <button class="hidden-bar-opener"><span class="icon fa fa-bars"></span></button>
//                         </div>
//                         {/* <!-- / Hidden Nav Toggler --> */}
                         
                        
//                         {/* <!-- Main Menu --> */}
//                         <nav class="main-menu">
                            
//                             <div class="navbar-header">
//                                 {/* <!-- Toggle Button -->    	 */}
//                                 <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
//                                     <span class="icon-bar"></span>
//                                     <span class="icon-bar"></span>
//                                     <span class="icon-bar"></span>
//                                 </button>
//                             </div>
                            
//                             <div class="navbar-collapse collapse clearfix">
//                                 <ul class="navigation">
//                                     <li class="dropdown"><a href="/dashboard/pending">Təsdiq gözləyənlər</a></li>
//                                     <li class="dropdown"><a href="/dashboard">Üzvlər</a></li>
//                                     <li class="dropdown"><a href="/dashboard/offers">Təkliflər</a></li>
//                                     <li class="dropdown"><a href="/dashboard/polls">Səsvermə</a></li>
//                                     <li class="dropdown"><a href="/dashboard/results">Səsvermə nəticələri</a></li>
//                                 </ul>
//                             </div>
//                         </nav>
//                         {/* <!-- Main Menu End--> */}
                        
//                         <nav>
//                         <ul>
//       <li>
//           <div onClick={handleLogout} className="link-box">
//             <p className='theme-btn cursor-pointer'>{user}</p>
//           </div>
//       </li>
//     </ul>

//     </nav>
                        
//                     </div>
//                 </div>
//             </div>
            
//         </header>
//     </>
//   );
// };

// export default DashboardHeader;

































































import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { footerLogo, logo, logoFooter, logoLeft, mpost } from "../../assets/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const DashboardHeader = () => {

  const user = localStorage.getItem("user");

  const { t } = useTranslation();
  const navigate = useNavigate(); 

  const handleLogout = () => {
    Swal.fire({
      title: 'Əminsiz?',
      text: "Admin paneldən çıxmaq istəyirsiz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bəli',
      cancelButtonText: 'Xeyr'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("login", "");
        localStorage.setItem("loginStatus", "Logged out successfully!");
        navigate("/");
        toast.error("Admin paneldən çıxıldı")
      }
    });
  };


  return (
    <>
   <nav className=" border-b text-[white] border-gray-200  h-[53px] mt-10 ">
        <div className="max-w-container mx-auto">
          <div className="container mx-auto flex items-center justify-between h-[53px] "> 
          <div>
            </div>  
            <div className="hidden space-x-2 text-[10px] xl:text-[15px] 2xl:text-base 3xl:text-[17px] 2xl:space-x-6 lg:flex gap-6 mr-20">
            <a href="/dashboard/pending" className="font-bold text-black hover:border-b-2 hover:border-[#E53037] ">{t("Təsdiq gözləyənlər")}</a>
            <a href="/dashboard" className="font-bold text-black hover:border-b-2 hover:border-[#E53037] ">{t("Üzvlər")}</a>
            <a href="/dashboard/offers" className="font-bold text-black hover:border-b-2 hover:border-[#E53037] ">{t("Təkliflər")}</a>
            <a href="/dashboard/applications" className="font-bold text-black hover:border-b-2 hover:border-[#E53037] ">{t("Müraciətlər")}</a>

          </div>
            <div className="hidden sml:block">  
              <button onClick={handleLogout}  className="bg-[#0086C9] text-[white] w-[100px] py-2 mr-2 rounded font-bold ">
              {t("Çıxış")}
              </button>
            </div>
          </div>
        </div>
      </nav>   
    </>
  ); 
};

export default DashboardHeader;


















