import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { footerLogo, logo, logoFooter, logoLeft, mpost } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { login, logout } from '../../Components/redux/authSlice';
import BrandSlider from "../BrandSlider/BrandSlider";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import axios from "axios";


const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});

const eventDate = new Date();
eventDate.setDate(eventDate.getDate() + 5); // 5 gün ekle
eventDate.setHours(12, 0, 0, 0); // Saat 12:00'yi ayarla



const HeaderMobile = () => {

  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(null); // Sadece bir menüyü açık tutmak için state
  const timeoutRef = useRef(null);
  const userId = useSelector((state) => state.auth.userId); // Redux'dan userId'yi al
  const member = useSelector((state) => state.auth.member); // Redux'dan userId'yi al

  const toggleInput = () => {
    setInputVisible(!isInputVisible);
  };




  const [isInputVisible, setInputVisible] = useState(false);

  const [temperature, setTemperature] = useState(null);
  const [windSpeed, setWindSpeed] = useState(null);
  const [error, setError] = useState(null);
  const API_KEY = "3ffb45181d104a49ac462116242910"; // Burada öz API açarınızı daxil edin
  const city = "Baku"; // Şəhəri burada dəyişə bilərsiniz
  const [rates, setRates] = useState({});
  
  
  const [timeLeft, setTimeLeft] = useState({});

  // Kalan zamanı hesapla
  function calculateTimeLeft() {
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  useEffect(() => {
    const fetchWindSpeed = async () => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${city}`
        );
        // Küləyin sürətini almaq
        setTemperature(response.data.current.temp_c); // Hava temperaturu °C olaraq
        setWindSpeed(response.data.current.wind_kph); // Külək sürətini km/saat olaraq alır
      } catch (error) {
        setError("Hava məlumatı yüklənərkən xəta baş verdi.");
      }
    };

    fetchWindSpeed();
  }, [city, API_KEY]);


 



  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };



  // Hover ile menü açma fonksiyonu
  const openMenuOnHover = (menuName) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setOpenMenu(menuName);
  };

  // Hover ile menü kapama fonksiyonu
  const closeMenuOnHover = () => {
    timeoutRef.current = setTimeout(() => {
      setOpenMenu(null);
    }, 200);
  };
  

  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isServicesOpen2, setIsServicesOpen2] = useState(false);
  const [isServicesOpen3, setIsServicesOpen3] = useState(false);
  const [isServicesOpen4, setIsServicesOpen4] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);
  

    // Hamburger menüsünü aç/kapa
    const toggleHamburgerMenu = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
      };


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };
  const openServicesMenu2 = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen2(true);
  };

  const openServicesMenu3 = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen3(true);
  };

  const openServicesMenu4 = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen4(true);
  };



  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const closeServicesMenu2 = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen2(false);
    }, 200);
  };
  const closeServicesMenu3 = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen3(false);
    }, 200);
  };
  const closeServicesMenu4 = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen4(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const navigate = useNavigate()
  const dispatch = useDispatch()

  
  const handleLogout = () => {
    Swal.fire({
      title: 'Əminsiz?',
      text: "Hesabınızdan çıxmaq istəyirsiz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bəli',
      cancelButtonText: 'Xeyr'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        navigate("/");
        toast.error("Hesabınızdan çıxıldı")
      }
    });
  };

  return (
    <>
       <div className="md:hidden max-w-containerSm flex justify-between mx-auto">
   <BrandSlider/>
   </div>

   <div className="md:hidden max-w-containerSm flex justify-between mx-auto mt-2">
   <div className="flex space-x-4">
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com" className="text-[#d62976]">
            <i className="fa-brands fa-instagram" style={{ fontSize: '20px' }}></i>

            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com" className="text-[#0086C9]"> 
              <i className="fa-brands fa-facebook" style={{ fontSize: '20px' }}></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com" className="text-red-500">
              <i className="fa-brands fa-youtube" style={{ fontSize: '20px' }}></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com" className="text-[#0086C9]">
              <i className="fa-brands fa-linkedin" style={{ fontSize: '20px' }}></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com" className="text-black">
              <i className="fa-brands fa-tiktok" style={{ fontSize: '20px' }}></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com" className="text-white">
              <i className="fa-brands fa-twitter" style={{ fontSize: '20px' }}></i>
            </a>
          </div> 
          <div  onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-bars text-[24px]"></i>
          </div>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
                 <div className="flex">
                 <div className="absolute top-8 right-20">
           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
           </div>
          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px]"></i>
          </button>
          
                 </div>
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logoLeft} alt="Logo" className="h-[90px]" /></a>
       <a className={`text-base text-left font-normal `} href="/"> {t("Əsas səhifə")}</a>
              <a href="/products" className={`text-base text-left font-normal`}>{t("Məhsullar")}</a>
              <a href="/about" className={`text-base text-left font-normal `}>{t("Haqqımızda")}</a>
              <a href="/services" className={`text-base text-left font-normal `}>{t("Xidmətlər")}</a>
              <a href="/blogs" className={`text-base text-left  font-normal `}>{t("Bloq")}</a>
              <a href="/partners" className={`text-base text-left font-normal `}>{t("Partnyorlar")}</a>
              <a href="/contact" className={`text-base text-left font-normal `}>{t("Əlaqə")}</a>
              <button
              onClick={toggleDropdown}
              className="flex items-center px-4 py-1 border rounded-full"
            >
              <span>
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-6 w-5 mr-3"
              />
            </span>
            <span className='text-[white]'>{selectedLanguage.label}</span>
            </button>

            {isOpen && (
              <div className="absolute right-0 top-10 mt-2 w-28 rounded-md shadow-lg bg-[#0086C9] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-teal-400"
                    >
                       <img
                      src={language.flag}
                      alt={`${language.label} Flag`}
                      className="h-6 w-5"
                    />
                    <span>{language.label}</span>
                    </button> 
                  ))}
                </div>
              </div>
            )}
             <div className="flex items-center">
            <i className="fas fa-temperature-high text-[#0086C9] mr-2"></i>
            <p>{temperature} °C</p> {/* Hava temperaturu */}
          </div>
          <div className="flex items-center">
            <i className="fas fa-wind text-[#0086C9] mr-2"></i>
            <p>{windSpeed} m/san</p> {/* Küləyin sürəti */}
          </div>
          <div className="flex items-center">
          <a href="/notifications"><i className="fa-solid fa-bell text-[#0086C9]" style={{ fontSize: '24px' }}></i></a>
          </div>

    </div>

          </div>
          </div>
        </div>
      )} 
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
          <button className="absolute top-5 right-5 text-white bg-[#0086C9] p-2 rounded" onClick={toggleHamburgerMenu}>
            <FaTimes />
          </button>
          <div className="flex flex-col items-start p-8 w-full">
          
           
          
            <div className=" items-center w-[80%] mt-10 justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="items-center gap-8 h-[480px]"> 


            <div className="w-full relative mb-5">
        <a href="/">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] bg-[#F5F5F5] text-[black] rounded-lg text-left pl-4"
        >
          {t("Əsas səhifə")}
        </button>
        </a>
      </div>
      {/* Button 1 */} 
      <div className="w-full relative z-50 mb-5"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu1')}
  className="w-full h-[50px] bg-[#F5F5F5] text-[black] rounded-lg flex items-center justify-between px-4"
>
  <span>Haqqımızda</span>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu1' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/about" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Məlumat")}
</a>

      <a href="/team" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Komandamız")}
      </a>
      <a href="/partners" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Tərəfdaşlarımız")}
      </a>
      <a href="/faq" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("FAQ")}
      </a>
    </div>
  )}
</div>


      {/* Button 2 */}
      <div className="w-full relative mb-5">
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full h-[50px] bg-[#F5F5F5] text-[black] rounded-lg flex items-center justify-between px-4"
>
  <span>{t("Xidmətlər")}</span>
  <i className="fas fa-chevron-down text-black"></i>
</button>

        
        {activeMenu === 'menu2' && (
          <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50">
            <a href="/events" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Tədbirlər")}
            </a>
          </div> 
        )}
      </div>

      {/* Button 4 */}
      <div className="w-full relative mb-5">
  <button
    onClick={() => toggleMenu('menu4')}
    className="w-full h-[50px] bg-[rgb(245,245,245)] text-[black] rounded-lg flex items-center justify-between px-4"
  >
    <span>{t("İşlərimiz")}</span>
    <i className="fas fa-chevron-down text-black"></i>
  </button>

  {activeMenu === 'menu4' && (
    <div className="absolute left-0 right-0 mt-2 max-h-[300px] overflow-y-auto space-y-2 bg-gray-100 rounded shadow-lg z-50">
      <a href="/services1" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Xəbərlər")}
      </a>
      <a href="/services2" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Statistika")}
      </a>
    </div>
  )}
</div>

<div className="w-full relative mb-5">
  <button
    onClick={() => toggleMenu('menu5')}
    className="w-full h-[50px] bg-[rgb(245,245,245)] text-[black] rounded-lg flex items-center justify-between px-4"
  >
    <span>{t("Sənədlər")}</span>
    <i className="fas fa-chevron-down text-black"></i>
  </button>

  {activeMenu === 'menu5' && (
    <div className="absolute left-0 right-0 mt-2 max-h-[300px] overflow-y-auto space-y-2 bg-gray-100 rounded shadow-lg z-50">
      <a href="/services1" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Qanunvericilik")}
      </a>
      <a href="/services2" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Sənəd nümunələri")}
      </a>
    </div>
  )}
</div>

<div className="w-full relative mb-5">
        <a href="/offers">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] bg-[#F5F5F5] text-[black] rounded-lg text-left pl-4"
        >
          {t("Rəy və təklif")}
        </button>
        </a>
      </div>

      <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] bg-[#F5F5F5] text-[black] rounded-lg text-left pl-4"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>

    </div>
    <div className="items-center w-full">
             <div className="flex items-center">
            <i className="fas fa-temperature-high text-[#0086C9] mr-2"></i>
            <p>{temperature} °C</p> {/* Hava temperaturu */}
          </div>
          <div className="flex items-center">
            <i className="fas fa-wind text-[#0086C9] mr-2"></i>
            <p>{windSpeed} m/san</p> {/* Küləyin sürəti */}
          </div>
          <div className="flex items-center">
          <a href="/notifications"><i className="fa-solid fa-bell text-[#0086C9] mb-2" style={{ fontSize: '24px' }}></i></a>
          </div>
              <button
              onClick={toggleDropdown}
              className="flex items-center space-x-1 px-3 py-1 border rounded-full border-[#264D92] text-sm sm:text-base mt-6 sm:mt-0"
              style={{ minWidth: "70px" }}
            >
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-4 w-4 sm:h-5 sm:w-5 mr-1"
              />
              <span className="text-black">{selectedLanguage.label}</span>
            </button>
            {isOpen && (
              <div className="absolute  w-28 rounded-md shadow-lg bg-[#264D92] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#5e8ee0]"
                    >
                      <img src={language.flag} alt={`${language.label} Flag`} className="h-4 w-4 mr-1" />
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
            </div>
    


          </div>
          </div>
        </div>
      )}   
   </div>
    </>
  ); 
};

export default HeaderMobile;