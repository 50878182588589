import React from 'react';
import './BrandSlider.css';

const BrandSlider = () => {
  const brands = [
    "Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir.", 
    "Yüksək azot tutumuna malik karbamid gübrəsinin istehsalı Azərbaycanın kənd təsərrüfatının inkişafına mühüm töhfə verəcək",
  ];

  return (
    <div className="slider-container">
      <div className="left-banner"></div> {/* Sol sancak */}
      <div className="slider">
        {brands.concat(brands).map((brand, index) => (
          <span key={index} className="brand-item">
            {brand}
          </span>
        ))}
      </div>
      <div className="right-banner"></div> {/* Sağ sancak */}
    </div>
  );
};

export default BrandSlider;
