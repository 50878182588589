import React, { useState } from 'react'
import Heading from "../../Components/home/Heading";
import { useTranslation } from 'react-i18next';
import { word } from '../../assets';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Modal from 'react-modal';


const files = [ 
  { name: 'Ərizə forması 1', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Ərizə forması 2', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Ərizə forması 3', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Ərizə forması 4', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Ərizə forması 5', url: 'assets/downloads/PDFInfo.pdf' },
];




const Documents = () => {

  const { t, i18n } = useTranslation();
      

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );






  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);

  const [showUpload2, setShowUpload2] = useState(false);
  const [showUpload3, setShowUpload3] = useState(false);
  const [showUpload4, setShowUpload4] = useState(false);

  const [selectedParty2, setSelectedParty2] = useState('');
  const [selectedParty3, setSelectedParty3] = useState('');
  const [selectedParty4, setSelectedParty4] = useState('');

  const [document1, setDocument1] = useState(null);
  const [document2, setDocument2] = useState(null);
  const [document3, setDocument3] = useState(null);
  const userId = useSelector((state) => state.auth.userId); // Redux'dan userId'yi al

  const handleOpenModal2 = () => {
    setIsOpen2(true);
    setShowUpload2(false);
  };

  const handleOpenModal3 = () => {
    setIsOpen3(true);
    setShowUpload3(false);
  };

  const handleOpenModal4 = () => {
    setIsOpen4(true);
    setShowUpload4(false);
  };
  const handleOpenModal5 = () => {
    setIsOpen5(true);
  };
  const handleOpenModal6 = () => {
    setIsOpen6(true);
  };

  const handleCloseModal2 = () => {
    setIsOpen2(false);
  };

  const handleCloseModal3 = () => {
    setIsOpen3(false);
  };

  const handleCloseModal4 = () => {
    setIsOpen4(false);
  };

  const handleCloseModal5 = () => {
    setIsOpen5(false);
  };
  const handleCloseModal6 = () => {
    setIsOpen6(false);
  };

  const handlePartySelection2 = (party) => {
    setSelectedParty2(party);
    setShowUpload2(true);
  };

  const handlePartySelection3 = (party) => {
    setSelectedParty3(party);
    setShowUpload3(true);
  };

  const handlePartySelection4 = (party) => {
    setSelectedParty4(party);
    setShowUpload4(true);
  };

  const handleFileChange2 = (event, setDocument) => {
    setDocument(event.target.files[0]); // Tek dosya seçildiği varsayılıyor
  };
  const handleFileChange3 = (event, setDocument) => {
    setDocument(event.target.files[0]); // Tek dosya seçildiği varsayılıyor
  };
  const handleFileChange4 = (event, setDocument) => {
    setDocument(event.target.files[0]); // Tek dosya seçildiği varsayılıyor
  };
  const handleFileChange5 = (event, setDocument) => {
    setDocument(event.target.files[0]); // Tek dosya seçildiği varsayılıyor
  };
  const handleFileChange6 = (event, setDocument) => {
    setDocument(event.target.files[0]); // Tek dosya seçildiği varsayılıyor
  };

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    // Dosyaları ve diğer bilgileri form verisi olarak oluştur
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('mainCategory', 'Maddi yardim'); // Main kategori sabit
    formData.append('secondCategory', selectedParty2); // Seçilen ikinci kategori 
    formData.append('document1', document1);
    formData.append('document2', document2);
    formData.append('document3', document3);

    console.log(userId, 'Maddi yardim', selectedParty2 )

    try {
      // Dosyaları ve bilgileri sunucuya gönder
      const response = await axios.post('https://www.hemkarbamid.az/hemkarbamid/applicat.php', formData);

      console.log(response.data); // Başarı mesajını logla
      alert('Müraciətiniz göndərildi');
    } catch (error) {
      console.error('Dosya yükleme hatası:', error);
      alert('Dosya yükleme sırasında hata oluştu');
    }

    handleCloseModal2(); // Modal'ı kapat
  };


  const handleSubmit3 = async (event) => {
    event.preventDefault();

    // Dosyaları ve diğer bilgileri form verisi olarak oluştur
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('mainCategory', 'Universitet'); // Main kategori sabit
    formData.append('secondCategory', selectedParty3); // Seçilen ikinci kategori
    formData.append('document1', document1);

    console.log(userId, 'Universitet', selectedParty3 )

    try {
      // Dosyaları ve bilgileri sunucuya gönder
      const response = await axios.post('https://www.hemkarbamid.az/hemkarbamid/applicat.php', formData);

      console.log(response.data); // Başarı mesajını logla
      alert('Müraciətiniz göndərildi');
    } catch (error) {
      console.error('Dosya yükleme hatası:', error);
      alert('Dosya yükleme sırasında hata oluştu');
    }

    handleCloseModal3(); // Modal'ı kapat
  };

  const handleSubmit4 = async (event) => {
    event.preventDefault();

    // Dosyaları ve diğer bilgileri form verisi olarak oluştur
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('mainCategory', 'Sinif'); // Main kategori sabit
    formData.append('secondCategory', selectedParty4); // Seçilen ikinci kategori
    formData.append('document1', document1);

    console.log(userId, 'Sinif', selectedParty4 )

    try {
      // Dosyaları ve bilgileri sunucuya gönder
      const response = await axios.post('https://www.hemkarbamid.az/hemkarbamid/applications.php', formData);

      console.log(response.data); // Başarı mesajını logla
      alert('Müraciətiniz göndərildi');
    } catch (error) {
      console.error('Dosya yükleme hatası:', error);
      alert('Dosya yükleme sırasında hata oluştu');
    }

    handleCloseModal4(); // Modal'ı kapat
  };

  const handleSubmit5 = async (event) => {
    event.preventDefault();

    // Dosyaları ve diğer bilgileri form verisi olarak oluştur
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('mainCategory', 'Mualice'); // Main kategori sabit
    formData.append('secondCategory', 'yoxdur'); // Seçilen ikinci kategori
    formData.append('document1', document1);
    formData.append('document2', document2);
    formData.append('document3', document3);


    try {
      // Dosyaları ve bilgileri sunucuya gönder
      const response = await axios.post('https://www.hemkarbamid.az/hemkarbamid/applications.php', formData);

      console.log(response.data); // Başarı mesajını logla
      alert('Müraciətiniz göndərildi');
    } catch (error) {
      console.error('Dosya yükleme hatası:', error);
      alert('Dosya yükleme sırasında hata oluştu');
    }

    handleCloseModal5(); // Modal'ı kapat
  };

  const handleSubmit6 = async (event) => {
    event.preventDefault();

    // Dosyaları ve diğer bilgileri form verisi olarak oluştur
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('mainCategory', 'Otel Istirahet'); // Main kategori sabit
    formData.append('secondCategory', 'yoxdur'); // Seçilen ikinci kategori
    formData.append('document1', document1);
    formData.append('document2', document2);


    try {
      // Dosyaları ve bilgileri sunucuya gönder
      const response = await axios.post('https://www.hemkarbamid.az/hemkarbamid/applications.php', formData);

      console.log(response.data); // Başarı mesajını logla
      alert('Müraciətiniz göndərildi');
    } catch (error) {
      console.error('Dosya yükleme hatası:', error);
      alert('Dosya yükleme sırasında hata oluştu');
    }

    handleCloseModal6(); // Modal'ı kapat
  };

  return (
  
     <>
         <Heading heading={t("Sənədlər")} title={t("Əsas")} subtitle={t("Sənədlər")} />
         <div className="app mt-10 mb-10">
    
    <div className='block md:flex'>
    <section className='w-full md:w-1/2'>
    <div>
    
        {/* <!--Styled Heading--> */}
        <div >
            <h2 className='md:text-3xl text-[22px] text-center font-semibold'>{t("Sənədlər")}</h2>
        </div>
      
            
        <div className="p-4 max-w-md mx-auto">
     {/* <div className="mb-4">
       <input
         type="text"
         placeholder="Axtar..."
         value={searchTerm}
         onChange={(e) => setSearchTerm(e.target.value)}
         className="w-full p-2 border rounded-md"
       />
     </div> */}
     <div className="file-list space-y-4">
       {filteredFiles.map((file, index) => (
         <div key={index} className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
           <p className="text-lg font-semibold">{file.name}</p>
           <a href={file.url} download>
             <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors">
               {t("Yüklə")}
             </button>
           </a>
         </div>
       ))}
       {filteredFiles.length === 0 && (
         <p className="text-center text-gray-500">{t("Fayl yoxdur")}</p>
       )}
     </div>
   </div>
        
    </div>
</section>


    <section className='w-full md:w-1/2 mt-10 md:mt-0'>
    <div class="auto-container">
    
        <div >
            <h2 className='md:text-3xl text-[22px] text-center font-semibold'>{t("Müraciətlər")}</h2>
        </div>
            
        <div className="p-4 max-w-md mx-auto">
     <div className="file-list space-y-4">
         <div className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
           <p className="text-lg font-semibold">Maddi yardim</p>
             <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors" onClick={handleOpenModal2}>Müraciət et</button>
         </div>
         <div className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
           <p className="text-lg font-semibold">Universitetə qəbul</p>
             <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors" onClick={handleOpenModal3}>Müraciət et</button>
         </div>
         <div className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
           <p className="text-lg font-semibold">Birinci sinif</p>
             <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors" onClick={handleOpenModal4}>Müraciət et</button>
         </div>
         <div className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
           <p className="text-lg font-semibold">Müalicə reabilitasiya</p>
             <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors" onClick={handleOpenModal5}>Müraciət et</button>
         </div>
         <div className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
           <p className="text-lg font-semibold">Otel, istirahət mərkəzləri</p>
             <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors" onClick={handleOpenModal6}>Müraciət et</button>
         </div>
       {filteredFiles.length === 0 && (
         <p className="text-center text-gray-500">{t("Fayl yoxdur")}</p>
       )}
     </div>
   </div>
            
            
            
        
    </div>
</section>

    </div>

     <Modal isOpen={isOpen2} onRequestClose={handleCloseModal2}>
       <p className='text-[25px]'>{showUpload2 ? 'Sənəd yükləmə' : 'Kateqoriya Seçimi'}</p>
       {!showUpload2 ? (
         <div>
           {/* Second category */}
          <div className='mt-10'>
          <button className='bg-[#0086C9] text-[white] p-2 rounded' onClick={() => handlePartySelection2('Maddi 1')}>
             Xəstəliyi təsdiq edən sənəd
           </button>
          </div>
         <div className='mt-2'>
         <button className='bg-[#0086C9] text-[white] p-2 rounded'  onClick={() => handlePartySelection2('Maddi 2')}>
             Ailədə tək işlədiyini təsdiq edən sənəd
           </button>
         </div >
         
           <div className='mt-2'>
           <button className='bg-[#0086C9] text-[white] p-2 rounded'   onClick={() => handlePartySelection2('Maddi 3')}>
             Vəfat edənin olduğunu təsdiq edən sənəd
           </button>
           </div>
           <div className='mt-2'>
           <button className='bg-[#0086C9] text-[white] p-2 rounded'  onClick={() => handlePartySelection2('Maddi 4')}>
             Aşağı əməkhaqqı aldığını təsdiqləyən sənəd
           </button>
           </div>
          <div className='mt-2'>
          <button className='bg-[#0086C9] text-[white] p-2 rounded'  onClick={() => handlePartySelection2('Maddi 5')}>
             Kirayədə qaldığını təsdiq edən sənəd
           </button>
          </div>
           <div className='mt-2'>
           <button className='bg-[#0086C9] text-[white] p-2 rounded'  onClick={() => handlePartySelection2('Maddi 6')}>
             Tək ailə başçısı olduğunu təsdiq edən sənəd
           </button>
           </div>
         </div>
       ) : (
         <form onSubmit={handleSubmit2}>
           {/* Document1 */}
           <p>Şəxsiyyət vəsiqəsini yüklə</p>
           <input type="file" onChange={(e) => handleFileChange2(e, setDocument1)} />

           {/* Document2 */}
           <p>Ərizəni yüklə</p>
           <input type="file" onChange={(e) => handleFileChange2(e, setDocument2)} />

           <div>
           <button className='bg-[green] text-[white] p-2 rounded mt-2' type="submit">Göndər</button>
           </div>
         </form>
       )}
       <button className='bg-[#E53037] text-[white] p-2 rounded mt-2'  onClick={handleCloseModal2}>Bağla</button>
     </Modal>
     <Modal isOpen={isOpen3} onRequestClose={handleCloseModal3}>
       <p className='text-[25px]'>{showUpload3 ? 'Sənəd yükləmə' : 'Kateqoriya Seçimi'}</p>
       {!showUpload3 ? (
         <div className='mt-10'>
           {/* Second category */}
          <div className='mt-2'>
          <button className='bg-[#0086C9] text-[white] p-2 rounded' onClick={() => handlePartySelection3('Universitet 1')}>
             Universitetə qəbul haqqında arayış
           </button>
          </div>
           <div className='mt-2'>
           <button className='bg-[#0086C9] text-[white] p-2 rounded' onClick={() => handlePartySelection3('Universitet 2')}>
             Dimdən tələbənin qəbul olunduğunu təsdiq edən sənəd
           </button>
           </div>
         </div>
       ) : (
         <form onSubmit={handleSubmit3}>
           {/* Document1 */}
           <p>Arayışı yüklə</p>
           <input type="file" onChange={(e) => handleFileChange3(e, setDocument1)} />

          <div>
          <button className='bg-[green] text-[white] p-2 rounded mt-2' type="submit">Göndər</button>
          </div>
         </form>
       )}
       <div>
       <button className='bg-[#E53037] text-[white] p-2 rounded mt-2' onClick={handleCloseModal3}>Bağla</button>
       </div>
     </Modal>
     <Modal isOpen={isOpen4} onRequestClose={handleCloseModal4}>
       <p className='text-[30px]'>{showUpload4 ? 'Sənəd yükləmə' : 'Kateqoriya Seçimi'}</p>
       {!showUpload4 ? (
         <div className='mt-10'>
           {/* Second category */}
           <div className='mt-2'>
           <button className='bg-[#0086C9] text-[white] p-2 rounded' onClick={() => handlePartySelection4('Sinif 1')}>
             Məktəbdən arayış
           </button>
           </div>
          <div className='mt-2'>
          <button className='bg-[#0086C9] text-[white] p-2 rounded' onClick={() => handlePartySelection4('Sinif 2')}>
             Metirkanın surəti
           </button>
          </div>
         </div>
       ) : (
         <form onSubmit={handleSubmit4}>
           {/* Document1 */}
           <p>Arayışı yüklə</p>
           <input type="file" onChange={(e) => handleFileChange4(e, setDocument1)} />

           <div>
           <button className='bg-[green] text-[white] p-2 rounded mt-2' type="submit">Göndər</button>
           </div>
         </form>
       )}
       <button className='bg-[#E53037] text-[white] p-2 rounded mt-2'  onClick={handleCloseModal4}>Bağla</button>
     </Modal>
     <Modal isOpen={isOpen5} onRequestClose={handleCloseModal5}>
     <p className='text-[30px] mb-5'>{showUpload4 ? 'Sənəd yükləmə' : 'Kateqoriya Seçimi'}</p>
     <form onSubmit={handleSubmit5}>
           {/* Document1 */}
           <p>Həkimdən təqdimat tipli arayış</p>
           <input type="file" onChange={(e) => handleFileChange5(e, setDocument1)} />

             {/* Document2 */}
             <p>Ərizəni nümunəsi</p>
           <input type="file" onChange={(e) => handleFileChange5(e, setDocument2)} />

             {/* Document3 */}
             <p>Şəxsiyyəti təsdiq edən sənəd</p>
           <input type="file" onChange={(e) => handleFileChange5(e, setDocument3)} />

          <div> <button  className='bg-[green] text-[white] p-2 rounded mt-2' type="submit">Göndər</button></div>
         </form>
      <div> <button className='bg-[#E53037] text-[white] p-2 rounded mt-2' onClick={handleCloseModal5}>Bağla</button></div>
     </Modal>
     <Modal isOpen={isOpen6} onRequestClose={handleCloseModal6}>

     <p className='text-[30px] mb-5'>{showUpload4 ? 'Sənəd yükləmə' : 'Kateqoriya Seçimi'}</p>
     <form onSubmit={handleSubmit6}>
           {/* Document1 */}
           <p>Ərizə forması</p>
           <input type="file" onChange={(e) => handleFileChange6(e, setDocument1)} />
  
             {/* Document2 */}
             <p>Şəxsiyyəti təsdiq edən sənəd</p>
           <input type="file" onChange={(e) => handleFileChange6(e, setDocument2)} />


         <div>
         <button className='bg-[green] text-[white] p-2 rounded mt-2' type="submit">Göndər</button>
         </div>
         </form>
       <button className='bg-[#E53037] text-[white] p-2 rounded mt-2' onClick={handleCloseModal6}>Bağla</button>
     </Modal>
   </div>
         </>
  )
}
 
export default Documents                         